import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import { setSelectedUserId, getMessages, updateIsMessagesLoading, setIsChatScreenOpen, updateUsersNickname, updateUserList, blockUserAndCancelUserSubscriptionAsync, setChatId, updateReadMessageCount } from './../../actions/chatActions'
import classNames from 'classnames'
import Loader from '../Layouts/Loader'
import ChatFilter from './ChatFilter'
import ModalPopUp from '../Layouts/ModalPopUp'
import Button from '../Layouts/Button'
import { removeConfirmSweetAlert, setAlertLoader, setConfirmSweetAlert, setSweetAlert } from '../../actions/sweetAlertActions'
import { markChatAsUnread, muteMassMessageForUserAsync, unmuteMassMessageForUserAsync, updateUserBlockedStatusAsync, updateUsersNickNameAsync } from '../../actions/adminActions'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'

const ContainerDiv = styled.div`
    background-color: ${props => props.chatUserListItemBackgroundColor};
    color: ${props => props.chatUserListItemFontColor};

    .selected-user {
        background-color: ${props => props.chatActiveUserListItemBackgroundColor};
        color: ${props => props.chatActiveUserListItemFontColor};
    }

    .dropdown-item:hover {
        background-color: rgb(54,54,54) !important;
        color: white !important;
    }

    @media (max-width: 768px) {
        width: 100% !important;
        max-width: 100% !important;
    }
`

const MessageRow = styled.div`
    padding: 4px;
    position: relative;
    border-bottom: 1px solid ${props => props.borderColor};
    cursor: pointer;
    min-height: 72px;
    font-size: 14px;

    .earnings {
        color: #32CD32;
    }

    .nickname {
        color: #4169e1;
    }

    .flex-1 {
        flex: 1;
    }

    :hover {
        background-color: rgba(0, 0, 0, 0.08);
    }
`

const Image = styled.img`
    min-width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;

    @media (max-width: 451px) {
        left: 0;
    }
`

const Badge = styled.span`
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color} !important;
    padding: .35em .65em !important;
    font-size: 12px !important;
`

const UserLastMessageDetails = styled.div`
    word-break: break-all;
    width: calc(100% - 130px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

const TimeDiv = styled.span`
    position: absolute;
    right: 10px;
    bottom: 5px;
`

const Dropdown = styled.span`
    cursor: pointer;
    .fa-ellipsis-v {
        padding: 10px;
    }

    .dropdown-menu {
        z-index: 1;
        right: 10px;
        left: auto;
    }
`

const DropdownActionLink = styled.div`
    cursor: pointer;

    &:hover, &:active, &:focus {
        background: transparent !important;
        color: #16181b !important;
    }
`

const getTimeSpace = (dateString) => {
    return moment(dateString).fromNow()
}

function ChatUserList(props) {
    const { chat, auth, colorScheme } = props
    const {
        chatActiveUserListItemBackgroundColor,
        chatActiveUserListItemFontColor,
        chatUserListItemFontColor,
        chatUserListItemBackgroundColor
    } = colorScheme
    const {
        hide_earnings,
        is_enable_sort_by_subscription_expire,
        is_enable_user_block_access,
        is_enable_mute_unmute_mass_message,
        is_enable_mark_as_unread,
        model_id
    } = auth.appSettings
    const { role } = auth.user
    const {
        userList,
        isLoading,
        selectedUserId
    } = chat

    const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false)
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(-1)
    const [isNicknameModalOpen, setIsNicknameModalOpen] = useState(false)
    const [nickname, setNickname] = useState('')
    const [isActionLoading, setIsActionLoading] = useState(false)
    const [confirmationData, setConfirmationData] = useState({})
    const [activeUserId, setActiveUserId] = useState('')

    let hideEarnings = false
    if (role === 'content_manager' && hide_earnings) {
        hideEarnings = true
    }

    if (role === 'live_stream_manager') {
        hideEarnings = true
    }

    useEffect(() => {
        setActiveDropdownIndex(-1)
        setIsActionDropdownOpen(false)
    }, [])

    useEffect(() => {
        // Do nothing
    }, [userList, isLoading])

    const subscriptionText = (date) => {
        let formattedDate = moment().diff(date, 'day')
        if (formattedDate < 0) {
            formattedDate = -formattedDate
            return `Subscription will end in ${formattedDate} days`
        }
        if (formattedDate > 0) {
            return `Subscription ended ${formattedDate} days ago`
        }
    }

    const userWalletAmount = (userData) => {
        const wallet_amount = _.get(userData, 'wallet_amount', false)
        if ((role === 'admin' || role === 'sub_admin') && (wallet_amount !== false && wallet_amount !== null)) {
            return <span><i className='fas fa-wallet'></i>&nbsp;${wallet_amount.toFixed(2)}&nbsp;</span>
        }
    }

    const redirectToChatPage = (userId) => {
        if (userId === selectedUserId) return
        props.setIsChatScreenOpen(true)
        props.setSelectedUserId(userId, props.history)
        props.updateIsMessagesLoading(true)
        const modelId = props.auth.appSettings.model_id

        let data = {
            userId: props.auth.user._id,
            selectedUserId: userId,
            selectedModelId: modelId,
            pageNum: 1,
            role: props.auth.user.role
        }
        props.getMessages(data, props.auth.user.isAdmin)
        props.updateReadMessageCount({ userId: userId })
    }

    const redirectToPurchasedContentPage = () => {
        setTimeout(() => {
            props.history.push(`/purchased-contents/${activeUserId}`)
        }, 100)
    }

    const handleClickOnUserList = (i) => {
        setActiveDropdownIndex(i)
        if (isActionDropdownOpen) {
            setIsActionDropdownOpen(false)
        }
    }

    const toggleDropdown = (i) => {
        const index = ((i !== activeDropdownIndex) || !isActionDropdownOpen) ? i : -1
        const isDropDownGonnaOpen = i !== activeDropdownIndex ? true : !isActionDropdownOpen
        setActiveDropdownIndex(index)
        setIsActionDropdownOpen(isDropDownGonnaOpen)
    }

    const openNicknameModel = (id) => {
        const user = userList && userList.find(el => el._id === id)
        if (user) {
            const userNickname = user.nick_name
            if (userNickname) setNickname(userNickname)
            setIsNicknameModalOpen(true)
        }
    }

    const updateNickName = async () => {
        const nick_name = nickname.trim()
        const nickNameLength = nick_name.length
        if (nickNameLength > 16) {
            const description = 'Nickname can be max 16 characters long'
            props.setSweetAlert({ description })
            return
        }
        const data = {
            user_id: activeUserId,
            nick_name
        }

        setIsActionLoading(true)
        const res = await props.updateUsersNickNameAsync(data)
        if (res && res.success === 1) {
            const newUserList = userList
            const updatedUser = newUserList.find(user => user._id === activeUserId)
            updatedUser.nick_name = nick_name
            props.updateUserList(newUserList)
            props.updateUsersNickname({ userId: activeUserId, nickName: nick_name })
        }
        setNickname('')
        setIsActionLoading(false)
        setIsNicknameModalOpen(false)
    }

    const blockUserAccess = async (userData) => {
        const { userId } = userData
        // this.toggleDropdown(index)

        const data = {
            userId: userId,
            blockFromChat: true
        }
        const payment_api_version = _.get(props.auth.appSettings, 'payment_api_version', 'v1')
        if (payment_api_version === 'v1') {
            props.updateUserBlockedStatusAsync(data)
        } else {
            const res = await props.blockUserAndCancelUserSubscriptionAsync(data)
            if (res && res.success === 1) {
                let newUserList = userList
                newUserList = newUserList.filter(user => user._id !== activeUserId)
                props.updateUserList(newUserList)
                setTimeout(() => {
                    props.history.push('/chat-new')
                    props.setChatId('')
                    props.setIsChatScreenOpen(false)
                }, 100)
            }
        }
    }

    const muteMassMessage = async (userData) => {
        const { userId } = userData
        let data = {
            userId: userId,
            muteMassMessage: true,
            muteFromChat: true
        }
        const res = await props.muteMassMessageForUserAsync(data)
        if (res && res.success === 1) {
            const newUserList = userList
            const updatedUser = newUserList.find(user => user._id === activeUserId)
            updatedUser.mute_mass_message = true
            props.updateUserList(newUserList)
        }
    }

    const unmuteMassMessage = async (userData) => {
        const { userId } = userData
        let data = {
            userId: userId,
            muteMassMessage: false,
            muteFromChat: true
        }
        const res = await props.unmuteMassMessageForUserAsync(data)
        if (res && res.success === 1) {
            const newUserList = userList
            const updatedUser = newUserList.find(user => user._id === activeUserId)
            updatedUser.mute_mass_message = false
            props.updateUserList(newUserList)
        }
    }

    const markChatAsUnread = async (data) => {
        const { userId, modelId } = data

        const userData = {
            userId: userId,
            modelId: modelId
        }

        const res = await props.markChatAsUnread(userData)
        if (res && res.success === 1) {
            props.removeConfirmSweetAlert()
            const newUserList = userList
            const updatedUser = newUserList.find(user => user._id === activeUserId)
            updatedUser.unreadcount.model_unread_message = 0
            props.updateUserList(newUserList)
        }
        props.removeConfirmSweetAlert()
    }

    const sweetAlertConfirmationHelper = () => {
        props.setAlertLoader(true)
        const { confirmationFor, data } = confirmationData
        switch (confirmationFor) {
            case 'mark_as_unread':
                markChatAsUnread(data)
                break
            case 'mute_from_mass_message':
                muteMassMessage(data)
                break
            case 'unmute_from_mass_message':
                unmuteMassMessage(data)
                break
            case 'block_user':
                blockUserAccess(data)
                break
            default:
                break
        }
    }

    return (
        <ContainerDiv
            chatUserListItemBackgroundColor={chatUserListItemBackgroundColor}
            chatUserListItemFontColor={chatUserListItemFontColor}
            chatActiveUserListItemBackgroundColor={chatActiveUserListItemBackgroundColor}
            chatActiveUserListItemFontColor={chatActiveUserListItemFontColor}>
            <ChatFilter
                colorScheme={colorScheme}
                setPage={props.setPage} />
            {(isLoading && props.loadMoreUser === false)
                ?
                <div className='pt-5 d-flex justify-content-center'>
                    <Loader
                        color={chatUserListItemFontColor}
                        loading={true}
                        size='10' />
                </div>
                :
                userList.length > 0 ? userList.map((user, i) => {
                    return (
                        <div
                            onClick={() => { handleClickOnUserList(i) }}
                            className={classNames('position-relative user', {
                                'selected-user': selectedUserId === user._id
                            })}
                            key={i}>
                            <MessageRow
                                className='d-flex align-items-center'
                                chatActiveUserListItemBackgroundColor={chatActiveUserListItemBackgroundColor}
                                borderColor={chatUserListItemFontColor}
                                onClick={() => redirectToChatPage(user._id)}
                            >
                                <Image src={user.avatarUrl ? user.avatarUrl : getCloudFrontAssetsUrl('faces/avatar.png')} alt='User Avatar' />
                                <div className='flex-1'>
                                    <b>{user.name}</b>
                                    {(role === 'admin' && user.has_complimentary_subscription) && <i className='fas fa-star ms-sm-2'></i>}
                                    <br />
                                    <b>
                                        {userWalletAmount(user)}
                                        {!hideEarnings && <span className='earnings'>
                                            {user.total_amount_spent ? `($${parseFloat(user.total_amount_spent).toFixed(2)})` : '($0.00)'}
                                        </span>}
                                        {(role === 'admin' || role === 'sub_admin') &&
                                            <span>&nbsp;{user.should_hide_in_chat ? '(Hidden for model)' : ''}</span>
                                        }
                                    </b>
                                    {role === 'admin' || role === 'sub_admin' || (role === 'model' && is_enable_sort_by_subscription_expire) ?
                                        <>
                                            {user.ccbill_subscription_status === '1' && user.expiration_date !== undefined &&
                                                <div>
                                                    <span className='text-danger'>{subscriptionText(user.expiration_date)}</span>
                                                </div>
                                            }
                                        </> : <></>}
                                    <div className='mb-1'>
                                        <b className='nickname'>{user.nick_name}</b>
                                    </div>
                                    <UserLastMessageDetails>{user.last_message}</UserLastMessageDetails>
                                </div>
                                <TimeDiv>
                                    {user.unreadcount && user.unreadcount.model_unread_message > 0 ? <Badge
                                        className='badge'
                                        backgroundColor={chatUserListItemFontColor}
                                        color={chatUserListItemBackgroundColor}>
                                        {user.unreadcount.model_unread_message}
                                    </Badge> : null}&nbsp;&nbsp;
                                    <i className='fas fa-clock' />&nbsp;{getTimeSpace(user.last_message_time)}
                                </TimeDiv>
                            </MessageRow>
                            <div className='position-absolute top-0 end-0'>{
                                role !== 'live_stream_manager' &&
                                <Dropdown className='dropdown-action'>
                                    <i className='fas fa-ellipsis-v' onClick={() => {
                                        toggleDropdown(i)
                                        setActiveUserId(user._id)
                                        if (window.screen.width > 991) redirectToChatPage(user._id)
                                    }}></i>
                                    {(isActionDropdownOpen && i === activeDropdownIndex) &&
                                        <div className='dropdown-menu show' aria-labelledby={i} >
                                            {!hideEarnings && <DropdownActionLink
                                                className='dropdown-item'
                                                onClick={redirectToPurchasedContentPage}>
                                                View Purchased
                                            </DropdownActionLink>}
                                            <DropdownActionLink
                                                className='dropdown-item'
                                                onClick={() => openNicknameModel(user._id)}
                                            >Update Nickname</DropdownActionLink>
                                            {(auth.isSuperAdmin || auth.isModel) && is_enable_user_block_access === true &&
                                                <DropdownActionLink
                                                    className='dropdown-item'
                                                    onClick={() => {
                                                        setConfirmationData({
                                                            showAlert: true,
                                                            confirmationFor: 'block_user',
                                                            data: { userId: user._id }
                                                        })
                                                        props.setConfirmSweetAlert({
                                                            description: `Are you sure you want to block ${user.name}?`
                                                        })
                                                    }}>
                                                    Block User</DropdownActionLink>
                                            }
                                            {is_enable_mute_unmute_mass_message === true &&
                                                <>
                                                    {(auth.isContentManager === false) ? user.mute_mass_message === true ?
                                                        <DropdownActionLink
                                                            className='dropdown-item'
                                                            onClick={() => {
                                                                setConfirmationData({
                                                                    showAlert: true,
                                                                    confirmationFor: 'unmute_from_mass_message',
                                                                    data: { userId: user._id }
                                                                })
                                                                props.setConfirmSweetAlert({
                                                                    description: `Are you sure you want to unmute mass messages for ${user.name}?`
                                                                })
                                                            }}>
                                                            Unmute mass messages</DropdownActionLink>
                                                        :
                                                        <DropdownActionLink
                                                            className='dropdown-item'
                                                            onClick={() => {
                                                                setConfirmationData({
                                                                    showAlert: true,
                                                                    confirmationFor: 'mute_from_mass_message',
                                                                    data: { userId: user._id }
                                                                })
                                                                props.setConfirmSweetAlert({
                                                                    description: `Are you sure you want to mute mass messages for ${user.name}?`
                                                                })
                                                            }}>
                                                            Mute mass message</DropdownActionLink>
                                                        : <></>
                                                    }
                                                </>
                                            }
                                            {(auth.isContentManager === true || auth.isModel === true) && is_enable_mark_as_unread === true && user.unreadcount.model_unread_message === 0 &&
                                                <DropdownActionLink
                                                    className='dropdown-item'
                                                    onClick={() => {
                                                        setConfirmationData({
                                                            showAlert: true,
                                                            confirmationFor: 'mark_as_unread',
                                                            data: { userId: user._id, modelId: model_id }
                                                        })
                                                        props.setConfirmSweetAlert({
                                                            description: `Are you sure you want to mark chat as unread for ${user.name}?`
                                                        })
                                                    }}>
                                                    Mark As Unread</DropdownActionLink>
                                            }
                                        </div>
                                    }
                                </Dropdown>
                            }</div>
                        </div>
                    )
                }) : <div className='text-center fs-6 pt-5'>No Records Found</div>
            }
            {confirmationData.showAlert &&
                <ConfirmSweetAlert
                    onConfirm={sweetAlertConfirmationHelper}
                    onCancel={() => setConfirmationData({ showAlert: false, confirmationFor: '', data: {} })} />}
            {isNicknameModalOpen && <ModalPopUp title='Update Nickname' handleClose={() => setIsNicknameModalOpen(isActionLoading)} closeBtnFontSize='initial'>
                <form
                    autoComplete='off'
                >
                    <div className='form-group'>
                        <input
                            type='text'
                            id='nick_name'
                            className='form-control'
                            placeholder='Enter Nickname'
                            name='nick_name'
                            defaultValue={nickname}
                            onChange={(e) => { setNickname(e.target.value) }}
                        />
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <Button
                                type='button'
                                classes='w-100 m-0'
                                onClick={updateNickName}
                                loading={isActionLoading}
                            >
                                {isActionLoading === true && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                Update </Button>
                        </div>
                    </div>
                </form>
            </ModalPopUp>}
            {(props.loadMoreUser === true && isLoading) &&
                <div className='text-center p-3'>
                    <Loader
                        color={chatUserListItemFontColor}
                        loading={isLoading}
                        size={10} />
                </div>
            }
        </ContainerDiv>
    )
}

ChatUserList.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setSelectedUserId: PropTypes.func.isRequired,
    getMessages: PropTypes.func.isRequired,
    setIsChatScreenOpen: PropTypes.func.isRequired,
    loadMoreUser: PropTypes.bool.isRequired,
    setPage: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    updateUsersNickNameAsync: PropTypes.func.isRequired,
    updateUsersNickname: PropTypes.func.isRequired,
    updateUserList: PropTypes.func.isRequired,
    setConfirmSweetAlert: PropTypes.func.isRequired,
    updateUserBlockedStatusAsync: PropTypes.func.isRequired,
    blockUserAndCancelUserSubscriptionAsync: PropTypes.func.isRequired,
    setAlertLoader: PropTypes.func.isRequired,
    setChatId: PropTypes.func.isRequired,
    muteMassMessageForUserAsync: PropTypes.func.isRequired,
    unmuteMassMessageForUserAsync: PropTypes.func.isRequired,
    removeConfirmSweetAlert: PropTypes.func.isRequired,
    markChatAsUnread: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired,
    updateIsMessagesLoading: PropTypes.func.isRequired,
    updateReadMessageCount: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    media: state.media
})

export default connect(
    mapStateToProps,
    {
        setSelectedUserId,
        getMessages,
        setIsChatScreenOpen,
        setSweetAlert,
        updateUsersNickNameAsync,
        updateUsersNickname,
        updateUserList,
        setConfirmSweetAlert,
        setAlertLoader,
        updateUserBlockedStatusAsync,
        blockUserAndCancelUserSubscriptionAsync,
        setChatId,
        muteMassMessageForUserAsync,
        unmuteMassMessageForUserAsync,
        removeConfirmSweetAlert,
        markChatAsUnread,
        updateIsMessagesLoading,
        updateReadMessageCount
    }
)(withRouter(ChatUserList))
