import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Loader from '../Layouts/Loader'
import styled from 'styled-components'
import classNames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import ChatHeader from './ChatHeader'
import ChatInputSection from './ChatInputSection'
import ChatMedia from './ChatMedia'
import socket from '../../utils/socket'
import { readMessage, addNewMessageInEnd, updateMessageAction, getMessages, updateIsMessagesLoading, sendTextMessage } from '../../actions/chatActions'

const StyledDiv = styled.div`
    margin: 0 30px;
    display: flex;
    flex-direction: column;

    .message {
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        max-width: 90%;
    }

    .receiver-message {
        background-color: ${props => props.chatReceiverCardBackgroundColor};
        color: ${props => props.chatReceiverCardFontColor};
        align-self: flex-start;
        padding: 0;
        box-shadow: 0px 0px 1px 1px;
        position: relative;
    }

    .sender-message {
        background-color: ${props => props.chatSenderCardBackgroundColor};
        color: ${props => props.chatSenderCardFontColor};
        align-self: flex-end;
        position: relative;
    }

    .tips {
        background-color: #9cff88;
        color: #000000;
        border: none;
    }
    
    .message.receiver-message.tips:after {
        content: '';
        display: block;
        left: -5px;
        top: 0;
        background-color: #9cff88;
        position: absolute;
        width: 20px;
        height: 10px;
        transform: skew(35deg, 8deg) rotate(-8deg);
        border-bottom: 0px;
        border-right: 0px;
        box-shadow: 0px 0px 1px 1px;
    }
    .message.sender-message:after {
        content: '';
        display: block;
        right: -3px;
        top: 0;
        background-color: ${props => props.chatSenderCardBackgroundColor};
        position: absolute;
        width: 20px;
        height: 10px;
        transform: skew(-49deg, 7deg) rotate(-8deg);
        border-bottom: 0px;
        border-right: 0px;
    }
    .message.receiver-message:after {
        content: '';
        display: block;
        left: -5px;
        top: 0;
        background-color: ${props => props.chatReceiverCardBackgroundColor};
        position: absolute;
        width: 20px;
        height: 10px;
        transform: skew(35deg, 8deg) rotate(-8deg);
        border-bottom: 0px;
        border-right: 0px;
        box-shadow: 0px 0px 1px 1px ${props => props.chatReceiverCardFontColor};
    }
    .main {
        padding: 10px;
        z-index: ${props => props.zIndex};
        position: relative;
        background: ${props => props.chatReceiverCardBackgroundColor};
        border-radius: 10px;
    }
    .message.receiver-message.tips .main {
        background: #9cff88;
    }

    .msg {
        word-wrap: break-word;
    }

    @media(max-width: 576px){
        padding-bottom: 43px;
    }
    &.chat-messages {
        min-height: 750px;
        justify-content: end;
    }

`

const TimeSpan = styled.span`
    font-size: 13px;
`

function Messages(props) {
    const [canChat, setCanChat] = useState(false)
    const [chatStartTime, setChatStartTime] = useState(null)
    const [chatEndTime, setChatEndTime] = useState(null)
    const [chatEndTimeWithDelay, setChatEndTimeWithDelay] = useState(null)
    const [countDownDuration, setCountDownDuration] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const { chat, auth, colorScheme } = props
    const {
        chatSenderCardBackgroundColor,
        chatSenderCardFontColor,
        chatSenderButtonBackgroundColor,
        chatSenderButtonFontColor,
        chatReceiverCardBackgroundColor,
        chatReceiverCardFontColor } = colorScheme
    const { isAdmin } = auth.user
    const { content_color, content_font_color } = auth.appSettings
    const { isMessagesLoading, messages, selectedUserId, userProfileInfo } = chat
    const selectedUserProfileDetails = userProfileInfo[selectedUserId]
    const [tempLoading, setTempLoading] = useState(true)
    const [isPopupOpen, setIsPopupOpen] = useState(false)

    const receiveMessage = () => {
        socket.on('MESSAGE_RECEIVE', (msg) => {
            const { isAdmin, _id, role } = props.auth.user
            const userIdString = _id.toString()
            const { selectedUserId, selectedModelId } = props.chat
            const { receiverId, senderId } = msg
            const receiverIdString = receiverId.toString()
            const senderIdString = senderId.toString()
            let shouldShowMessage = false

            // Show messages to all the admins if the message is to/from the same user as the open chat
            if (isAdmin && [receiverIdString, senderIdString].includes(selectedUserId)) {
                shouldShowMessage = true
            }

            // Show messages to users if the message is sent/received by them
            if (isAdmin === false && [receiverIdString, senderIdString].includes(userIdString)) {
                shouldShowMessage = true
            }

            if (shouldShowMessage) {
                let data = {
                    userId: selectedUserId
                }
                if ((isAdmin)) {
                    data.modelId = props.auth.appSettings.model_id ? props.auth.appSettings.model_id : selectedModelId
                }
                addMessage(msg)
                const chatList = document.getElementById('message-list')
                if (chatList) {
                    setTimeout(() => {
                        chatList.scrollTop = chatList.scrollHeight
                    }, 200)
                }
                if (role !== 'admin') {
                    props.readMessage(data, isAdmin)
                }
            }
        })
    }

    const addMessage = (message) => {
        props.addNewMessageInEnd(message)
        scrollToBottom()
    }

    useEffect(() => {
        const setupSocketConnection = () => {
            if (socket.disconnected) {
                socket.connect()
            }

            const roomData = {
                roomId: selectedUserId,
                userId: props.auth.user._id
            }
            socket.emit('JOIN_ROOM', roomData)

            if (isAdmin) {
                socket.emit('GET_ONLINE_USER_LIST')
                socket.emit('ADMIN_IN_USER_CHAT', { adminId: props.auth.user._id, userId: selectedUserId })
            } else if (typeof props.auth.user._id !== 'undefined' && props.auth.user.isAdmin === false && props.auth.user.role !== 'proxy_user') {
                socket.emit('USER_ONLINE', props.auth.user._id)
            }

            receiveMessage()
        }

        setupSocketConnection()

        return () => {
            socket.off('MESSAGE_RECEIVE')
        }
    }, [selectedUserId])

    useEffect(() => {
        if (props.match.params.id !== undefined && isMessagesLoading === false) {
            props.updateIsMessagesLoading(true)
            const userId = props.match.params.id
            const modelId = props.auth.appSettings.model_id

            let data = {
                userId: props.auth.user._id,
                selectedUserId: userId,
                selectedModelId: modelId,
                pageNum: pageNum,
                role: props.auth.user.role
            }
            props.getMessages(data, props.auth.user.isAdmin)
        }

        // Improves UX, when user come back again on chat page
        setTimeout(() => {
            setTempLoading(false)
        }, 500)
    }, [])

    // Scroll to bottom whenever messages change
    const scrollToBottom = () => {
        const objDiv = document.getElementById('messageList')
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    useEffect(() => {
        // Initial scroll
        scrollToBottom()

        // Add event listener for new content
        window.addEventListener('message', scrollToBottom)

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('message', scrollToBottom)
        }
    }, [messages])

    const handleTextMessageSend = (data, callback) => {
        props.sendTextMessage(data, (status) => {
            if (status === false) {
                props.history.push('profile/add-new-payment-method')
            } else {
                callback()
            }
        })
    }

    if (isMessagesLoading || tempLoading) {
        return (
            <div className='text-center mt-5'>
                <Loader loading={isMessagesLoading || tempLoading} size={10} />
            </div>
        )
    }

    return <div>
        {_.isEmpty(selectedUserId) === false &&
            <ChatHeader
                handleTextMessageSend={handleTextMessageSend}
                scrollToBottom={scrollToBottom}
                canChat={canChat}
                setCanChat={setCanChat}
                chatStartTime={chatStartTime}
                setChatStartTime={setChatStartTime}
                chatEndTime={chatEndTime}
                setChatEndTime={setChatEndTime}
                chatEndTimeWithDelay={chatEndTimeWithDelay}
                setChatEndTimeWithDelay={setChatEndTimeWithDelay}
                countDownDuration={countDownDuration}
                setCountDownDuration={setCountDownDuration}
                colorScheme={colorScheme}
            />
        }
        <StyledDiv
            className='chat-messages mt-2'
            contentColor={content_color}
            contentFontColor={content_font_color}
            chatSenderCardBackgroundColor={chatSenderCardBackgroundColor}
            chatSenderCardFontColor={chatSenderCardFontColor}
            chatReceiverCardBackgroundColor={chatReceiverCardBackgroundColor}
            chatReceiverCardFontColor={chatReceiverCardFontColor}
            zIndex={isPopupOpen ? '' : '1'}
        >
            {messages.length > 0 && messages.map((message, i) => {
                const isSenderAdmin = isAdmin && message.fromAdmin
                const isTipsMessage = message.type === 'tips'
                let chatMessage = message.message

                if (isTipsMessage) {
                    chatMessage = `${selectedUserProfileDetails ? selectedUserProfileDetails.name : 'user'} just tipped you ${message.message}!`
                }

                if (message.type === 'GO_LIVE_STREAM') {
                    chatMessage = `Go Live Stream with ${selectedUserProfileDetails ? selectedUserProfileDetails.name : 'user'} has ended.`
                }

                return (
                    <div
                        key={i}
                        className={
                            classNames('message', {
                                'receiver-message': !isSenderAdmin,
                                'sender-message': isSenderAdmin,
                                'tips': isTipsMessage
                            })
                        }
                    >
                        <div
                            className={
                                classNames('', {
                                    'main': !isSenderAdmin
                                })
                            }
                        >
                            {['photo', 'video', 'gallery'].includes(message.type) &&
                                <ChatMedia
                                    buttonBackgroundColor={isSenderAdmin ? chatSenderButtonBackgroundColor : ''}
                                    buttonFontColor={isSenderAdmin ? chatSenderButtonFontColor : ''}
                                    message={message}
                                    setIsPopupOpen={setIsPopupOpen}
                                    index={i} />
                            }
                            {!_.isEmpty(chatMessage) && <>
                                <span className='msg'>{chatMessage}</span>
                                {isTipsMessage && !_.isEmpty(message.tipMessage) && <><br />{message.tipMessage}</>}
                                <br />
                            </>
                            }
                            <TimeSpan
                                className={
                                    classNames('text-muted', {
                                        'd-block text-right': isSenderAdmin
                                    })
                                }
                            >
                                {moment(message.created).calendar()}
                            </TimeSpan>
                        </div>
                    </div>
                )
            })}
        </StyledDiv>
        <ChatInputSection colorScheme={colorScheme} />
    </div >
}

Messages.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getMessages: PropTypes.func.isRequired,
    readMessage: PropTypes.func.isRequired,
    addNewMessageInEnd: PropTypes.func.isRequired,
    updateMessageAction: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired,
    updateIsMessagesLoading: PropTypes.func.isRequired,
    sendTextMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat
})

export default connect(
    mapStateToProps,
    {
        getMessages,
        readMessage,
        addNewMessageInEnd,
        updateMessageAction,
        updateIsMessagesLoading,
        sendTextMessage
    }
)(withRouter(Messages))
