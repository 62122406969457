import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import crypto from 'crypto'
import Countdown from 'react-countdown'
import momentTZ from 'moment-timezone'
import _ from 'lodash'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import socket from '../../utils/socket'
import Button from '../Layouts/Button'
import StreamWrapper from '../live-stream/StreamWrapper'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import {
    chatUserProfileInfoAction,
    setChatId,
    setSelectedChatModelId,
    readMessage,
    deleteMessageAction,
    addNewMessageInEnd,
    getUserMessages,
    showBootstrapNavbar,
    getUserSendMessageStatus,
    setAllowUserToChat,
    updateMessageAction
} from '../../actions/chatActions'
import { openCopyToClipboardToast } from '../../actions/copyToClipboardAction'
import { addToken, removeToken } from '../../actions/streamAction'
import { setSweetAlert } from '../../actions/sweetAlertActions'

const ViewLiveStreamDiv = styled.div`
    display: inline-block;
    position: absolute !important;
    right: 0;
    text-align: right;
    div {
        width: fit-content;
    }
    @media(max-width: 991px){
        display: ${props => props.applyAlternateCss ? 'flex' : 'block'};
        position: unset !important;
        left: initial;
        right: unset;
        margin-top: 10px;
        width: ${props => props.applyAlternateCss ? '50%' : '100%'};
        padding-left: ${props => props.applyAlternateCss ? '18px' : 'initial'};
        text-align: left;
        }
    @media(max-width:768px){
        display: block;
        width: 100%;
        text-align: center;
        padding-left: 0;
    }
`

const JoinStream = styled.div`
    text-align: end;
    position: absolute !important;
    width: fit-content !important;
    right: 0;

    @media(max-width: 991px) {
        display: ${props => props.applyAlternateCss ? 'flex' : 'block'};
        display: block;
        position: unset !important;
        margin-top: 10px;
        width: 50%;
        text-align: left;
        padding-left: 12px;
    }
    @media(max-width:768px) {
        display: block;
        width: 100%;
        text-align: center;
    }
`

const ViewAllowCheckBoxDiv = styled.div`
flex-basis: 100%;
display: inline-block;
position: absolute;
width: fit-content;
right: 12px;
margin-right: ${props => props.isModel ? '120px !important' : props.isViewSession ? '200px !important' : props.isJoinStreamShow === true ? '150px !important' : '0px !important'};

@media(max-width: 991px) {
        display: flex;
        position: unset;
        margin-top: 10px;
        // width: ${props => props.isModel ? '50%' : props.isViewSession ? '50%' : props.isJoinStreamShow === true ? '50%' : '100%'};
        float: right;
        justify-content: flex-end;
        margin-right: 0px !important;
        padding-right: 10px;
        text-align: right;

        .col-12.ml-sm-auto {
            padding-right: 0px !important;
        }
        .col-12.ml-sm-auto>.col-12 {
            padding-right: 0px !important;
        }
    }
    @media(max-width:768px) {
        display: block;
        // width: 100%;
        text-align: center !important;
        padding-right: 0px;

        .row.text-right {
            text-align: center !important;
        }
    }
`

const LiveStream = (props) => {
    const [isLive, setIsLive] = useState(false)
    const [streamJoined, setStreamJoined] = useState(false)
    const [streamToken, setStreamToken] = useState('')
    const [canModelGoLive, setCanModelGoLive] = useState(true)
    const [tabToken, setTabToken] = useState(crypto.randomBytes(6).toString('hex'))
    const [room, setRoom] = useState('')
    const [pubId, setPubId] = useState('')
    const [pubPvtId, setPubPvtId] = useState('')
    const [meetingStartTime, setMeetingStartTime] = useState('')
    const [sendGoLiveStreamMessage, setSendGoLiveStreamMessage] = useState(false)
    const [serverTabToken, setServerTabToken] = useState('')
    const [toastContent, setToastContent] = useState('')
    const [allowUserToChat, setAllowUserToChat] = useState(false)
    const {
        auth,
        receiverProfileDetails,
        receiverId,
        handleTextMessageSend,
        scrollToBottom,
        canChat,
        setCanChat,
        chatStartTime,
        chatEndTime,
        chatEndTimeWithDelay,
        onEndTimeCompleteTimer,
        onCompleteTimer,
        countDownDuration,
        countDownRemainingTime,
        loadSocketEvent
    } = props
    const { role, isAdmin } = props.auth.user
    const { chat } = props
    const { enable_live_stream_report_for_content_manager, chat_booking_enabled, chat_settings } = auth.appSettings
    const disabledNormalChat = chat_settings.disabled_normal_chat
    const showAllowUserToChatCheckBox = disabledNormalChat && chat_booking_enabled && auth.user.isAdmin
    const showViewGoLiveButton = receiverProfileDetails.isLiveStreamAvailable === true && (['admin', 'sub_admin'].includes(role)) ? true : role === 'content_manager' && enable_live_stream_report_for_content_manager === true ? true : false
    const meetingWord = chat_settings.meeting_word

    const timerProps = {
        isPlaying: true,
        size: 60,
        strokeWidth: 3,
        trailColor: 'transparent'
    }

    useEffect(() => {
        if (chat.selectedUserId === '' && props.match.params.id === undefined) {
            return props.history.push('/chat')
        }

        let roomId = ''
        if (props.match.params.id === undefined) {
            roomId = auth.user._id
        } else {
            roomId = props.match.params.id
        }
        const roomData = {
            roomId,
            userId: auth.user._id
        }

        if (auth.user.isAdmin === true) {
            socket.emit('GET_ONLINE_USER_LIST')
            const data = { adminId: auth.user._id, userId: props.match.params.id }
            socket.emit('ADMIN_IN_USER_CHAT', data)
        }
        if (typeof auth.user._id !== 'undefined' && auth.user.isAdmin === false && auth.user.role !== 'proxy_user') {
            socket.emit('USER_ONLINE', auth.user._id)
        }
        socket.emit('JOIN_ROOM', roomData)
        receiveMessage()
        socket.on('connect', () => {
            socket.emit('JOIN_ROOM', roomData)
            loadSocketEvent()
        })
        updateMessage()
        deleteMessage()
        if (auth.user.isAdmin === false) {
            updateSendMessageStatusForUser()
        }
        onGoLive()
        onStopLive()
        onStreamStarted()
        onLeftStream()
        onJoinLiveStream()
        onStreamAlreadyStarted()
        loadSocketEvent()
        const cleanup = () => {
            socket.emit('LEAVE_ROOM', roomData)
            socket.removeListener('MESSAGE_RECEIVE')
            socket.removeListener('UPDATE_MESSAGE')
            socket.removeListener('DELETE_MESSAGE')
            if (auth.user.isAdmin === false) {
                socket.removeListener('UPDATE_SEND_MESSAGE_STATUS')
            }
            socket.removeListener('LIVE_STARTED')
            socket.removeListener('USER_LEFT_STREAM')
            socket.removeListener('GO_LIVE')
            socket.removeListener('STOP_LIVE')
            socket.removeListener('USER_JOIN_STREAM')
            socket.removeListener('STREAM_ALREADY_STARTED')
            socket.removeListener('ONLINE_USER_LIST')
            socket.removeListener('USER_OFFLINE')
        }
        // refreshMessageList()

        if (chat.userProfileInfo[auth.user._id] === undefined) {
            props.chatUserProfileInfoAction(auth.user._id)
        }

        if (chat.selectedUserId !== '' || props.match.params.id !== undefined) {
            if (props.match.params.id === undefined) {
                if (chat.userProfileInfo[chat.selectedUserId] === undefined) {
                    props.chatUserProfileInfoAction(chat.selectedUserId)
                }
            } else {
                if (chat.userProfileInfo[props.match.params.id] === undefined) {
                    props.chatUserProfileInfoAction(props.match.params.id)
                    props.setChatId(props.match.params.id, props.history)
                    props.setSelectedChatModelId(auth.appSettings.model_id, props.history)
                }
            }
        }

        if (chat.userProfileInfo[auth.appSettings.model_id] === undefined) {
            props.chatUserProfileInfoAction(auth.appSettings.model_id)
        }
        const appSettings = auth.appSettings
        if (auth.user.isAdmin === true && appSettings.chat_booking_enabled && appSettings.chat_settings.disabled_normal_chat === true) {
            getUserSendMessageStatus()
        }
        const { enable_live_streaming } = props.auth.appSettings

        if (auth.user.isAdmin === false && enable_live_streaming === true && socket.isSocketConnected === true) {
            socket.emit('CHECK_STREAM_STARTED', auth.user._id)
        }

        if ((auth.user.role === 'model' || auth.user.role === 'live_stream_manager') && enable_live_streaming === true) {
            const data = {
                userId: roomId
            }
            props.addToken(data, (res) => {
                if (res.data.janus === 'success') {
                    setStreamToken(res.data.streamToken)
                }
            })
        }

        const onUnmount = () => {
            cleanup() // Perform the cleanup actions
            window.removeEventListener('beforeunload', onUnmount)
        }

        window.addEventListener('beforeunload', onUnmount, false)

        const { isAuthenticated, isAdmin } = auth

        if (isAuthenticated && (window.orientation !== undefined)) {
            let showProgressiveWebPopUp = localStorage.getItem('showProgressiveWebPopUp')
            if (showProgressiveWebPopUp === 'never') {
                // DO NOTHING
            } else if (!showProgressiveWebPopUp) {
                localStorage.setItem('showProgressiveWebPopUp', 1)
            } else if (showProgressiveWebPopUp !== 'false' && Number(showProgressiveWebPopUp) < 50) {
                showProgressiveWebPopUp = Number(showProgressiveWebPopUp) + 1
                localStorage.setItem('showProgressiveWebPopUp', showProgressiveWebPopUp)
            }
        }

        if (isAuthenticated && isAdmin === false) {
            const differentPasswordPopupReminderCount = localStorage.getItem('differentPasswordPopupReminderCount')
            if (differentPasswordPopupReminderCount && differentPasswordPopupReminderCount <= 50) {
                localStorage.setItem('differentPasswordPopupReminderCount', Number(differentPasswordPopupReminderCount) + 1)
            }
        }

        window.addEventListener('beforeunload', onUnmount, false)

        // Return the cleanup function in useEffect
        return () => {
            onUnmount() // Call onUnmount to remove listeners and clean up on component unmount
        }
    }, [])

    const deleteMessage = () => {
        socket.on('DELETE_MESSAGE', (msg) => {
            props.deleteMessageAction(msg)
        })
    }

    const updateSendMessageStatusForUser = () => {
        if (auth.user.isAdmin === false) {
            socket.on('UPDATE_SEND_MESSAGE_STATUS', (status) => {
                setCanChat(status)
            })
        }
    }

    // const refreshMessageList = () => {
    //     let userId, modelId
    //     if (props.match.params.id === undefined) {
    //         userId = auth.appSettings.model_id
    //         modelId = chat.selectedModelId
    //     } else {
    //         userId = props.match.params.id
    //         modelId = auth.appSettings.model_id
    //     }

    //     let data = {
    //         userId: auth.user._id,
    //         selectedUserId: userId,
    //         selectedModelId: modelId,
    //         pageNum: pageNum,
    //         role: auth.user.role
    //     }
    //     props.getUserMessages(data, auth.user.isAdmin, (success) => {
    //         if (success === true) {
    //             scrollToBottom()
    //         } else {
    //             setIsChatHidden(true)
    //         }
    //     })
    // }

    const onLeftStream = () => {
        socket.on('USER_LEFT_STREAM', () => {
            if (auth.user.isAdmin === true) {
                let receiverId
                if (props.match.params.id === undefined) {
                    receiverId = chat.selectedUserId
                } else {
                    receiverId = props.match.params.id
                }
                const receiverProfileDetails = (chat.userProfileInfo && chat.userProfileInfo[receiverId])
                    ? chat.userProfileInfo[receiverId]
                    : getCloudFrontAssetsUrl('faces/avatar.png')
                setToastContent(`${receiverProfileDetails.name} left stream.`)
                props.openCopyToClipboardToast('STREAM')
            } else {
                const messageObject = {
                    type: 'system',
                    fromAdmin: false,
                    message: 'leave',
                    receiver: chat.selectedUserId
                }
                handleTextMessageSend(messageObject, () => {
                    scrollToBottom()
                })
            }
        })
    }

    const onJoinLiveStream = () => {
        socket.on('USER_JOIN_STREAM', () => {
            if (auth.user.isAdmin === true) {
                let receiverId
                if (props.match.params.id === undefined) {
                    receiverId = chat.selectedUserId
                } else {
                    receiverId = props.match.params.id
                }
                const receiverProfileDetails = (chat.userProfileInfo && chat.userProfileInfo[receiverId])
                    ? chat.userProfileInfo[receiverId]
                    : getCloudFrontAssetsUrl('faces/avatar.png')
                props.openCopyToClipboardToast('STREAM')
                setToastContent(`${receiverProfileDetails.name} join stream.`)
            } else {
                const messageObject = {
                    type: 'system',
                    fromAdmin: false,
                    message: 'join',
                    receiver: chat.selectedUserId
                }
                handleTextMessageSend(messageObject, () => {
                    scrollToBottom()
                })
            }
        })
    }

    const onStreamAlreadyStarted = () => {
        socket.on('STREAM_ALREADY_STARTED', () => {
            if (auth.user.isAdmin === true) {
                props.setSweetAlert('Stream already started with another user.')
                setCanModelGoLive({ canModelGoLive: false })
            }
        })
    }

    const onGoLive = () => {
        setSendGoLiveStreamMessage(true)
        socket.on('GO_LIVE', () => {
            setIsLive(true)
        })
    }

    const onStopLive = () => {
        setSendGoLiveStreamMessage(false)
        socket.on('STOP_LIVE', () => {
            setIsLive(false)
            setStreamJoined(false)
        })
    }

    const receiveMessage = () => {
        socket.on('MESSAGE_RECEIVE', (msg) => {
            const { isAdmin, _id, role } = auth.user
            const userIdString = _id.toString()
            const { selectedUserId, selectedModelId } = chat
            const { receiverId, senderId } = msg
            const receiverIdString = receiverId.toString()
            const senderIdString = senderId.toString()
            let shouldShowMessage = false

            // Show messages to all the admins if the message is to/from the same user as the open chat
            if (isAdmin && [receiverIdString, senderIdString].includes(selectedUserId)) {
                shouldShowMessage = true
            }

            // Show messages to users if the message is sent/received by them
            if (isAdmin === false && [receiverIdString, senderIdString].includes(userIdString)) {
                shouldShowMessage = true
            }

            if (shouldShowMessage) {
                let data = {
                    userId: selectedUserId
                }
                if ((isAdmin)) {
                    data.modelId = selectedModelId
                }
                addMessage(msg)
                if (role !== 'admin') {
                    props.readMessage(data, isAdmin)
                }
            }
        })
    }

    const addMessage = (message) => {
        props.addNewMessageInEnd(message)
        scrollToBottom()
    }

    const updateMessage = () => {
        socket.on('UPDATE_MESSAGE', (msg) => {
            const messageId = _.get(msg, 'messageId', false)
            if (messageId !== false) {
                msg._id = messageId
            }
            props.updateMessageAction(msg)
        })
    }

    const onStreamStarted = () => {
        socket.on('LIVE_STARTED', (streamData) => {
            if (streamData === null) {
                setStreamJoined(false)
                setIsLive(false)
                return
            }
            if (streamData.streamToken !== undefined && streamData.room !== undefined) {
                const isLiveStreamStarted = _.get(streamData, 'isLiveStreamStarted', false)
                setRoom({ room: streamData.room })
                setPubId({ pubId: streamData.pubId })
                setPubPvtId(streamData.pubPvtId)
                setStreamToken({ streamToken: streamData.streamToken })
                setIsLive(isLiveStreamStarted)
                if (isLiveStreamStarted === true) {
                    if (auth.user.isAdmin === false) {
                        const messageObject = {
                            type: 'system',
                            fromAdmin: false,
                            message: 'join',
                            receiver: chat.selectedUserId
                        }
                        handleTextMessageSend(messageObject, () => {
                            setStreamJoined(true)
                        })
                    } else {
                        if (streamData.tabToken === tabToken) {
                            setCanModelGoLive(true)
                            setServerTabToken(streamData.tabToken)
                        } else {
                            setCanModelGoLive(false)
                            setServerTabToken(streamData.tabToken)
                        }
                    }
                } else {
                    if (auth.user.isAdmin === false) {
                        setStreamJoined(false)
                        setIsLive(false)
                        const messageObject = {
                            type: 'system',
                            fromAdmin: false,
                            message: 'leave',
                            receiver: chat.selectedUserId
                        }
                        handleTextMessageSend(messageObject, () => {
                            setStreamJoined(false)
                            setIsLive(false)
                        })
                    } else {
                        setCanModelGoLive(true)
                    }
                }
                if (auth.user.isAdmin === true) {
                    let receiverId
                    if (props.match.params.id === undefined) {
                        receiverId = chat.selectedUserId
                    } else {
                        receiverId = props.match.params.id
                    }
                    const receiverProfileDetails = (chat.userProfileInfo && chat.userProfileInfo[receiverId])
                        ? chat.userProfileInfo[receiverId]
                        : getCloudFrontAssetsUrl('faces/avatar.png')
                    if (streamData.isLiveStreamStarted === true && auth.user.isAdmin === true) {
                        props.openCopyToClipboardToast('STREAM')
                        setToastContent(`${receiverProfileDetails.name} join stream.`)
                    }
                }
            }
        })
    }

    const joinStream = () => {
        setStreamJoined(true)
        if (auth.user.isAdmin === false) {
            socket.emit('JOIN_STREAM', auth.user._id)
        }
    }

    const stopShowStream = () => {
        setStreamJoined(false)
        if (auth.user.isAdmin === false) {
            socket.emit('LEFT_STREAM', auth.user._id)
        }
    }

    const goLive = () => {
        props.showBootstrapNavbar(true)
        const messageObject = {
            sender: chat.selectedModelId,
            receiver: chat.selectedUserId,
            type: 'system',
            fromAdmin: true,
            message: 'start'
        }
        handleTextMessageSend(messageObject, () => {
            scrollToBottom()
            setIsLive(true)
        })
    }

    const stopLive = () => {
        props.showBootstrapNavbar(false)
        const liveStreamMessageObject = {
            sender: chat.selectedModelId,
            receiver: chat.selectedUserId,
            type: 'GO_LIVE_STREAM',
            fromAdmin: true,
            message: ''
        }
        handleTextMessageSend(liveStreamMessageObject, () => {
        })
        const messageObject = {
            sender: chat.selectedModelId,
            receiver: chat.selectedUserId,
            type: 'system',
            fromAdmin: true,
            message: 'stop'
        }
        handleTextMessageSend(messageObject, () => {
            setIsLive(false)
            scrollToBottom()
            setStreamJoined(false)
        })
    }

    const onChangeAllowUserToChat = (can_send_message) => {
        const userId = props.match.params.id
        const data = {
            can_send_message,
            userId
        }
        props.setAllowUserToChat(data, () => {
            setAllowUserToChat(can_send_message)
        })
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <></>
        } else {
            if (hours > 0) {
                minutes = minutes + (hours * 60)
            }
            if (seconds < 10) {
                seconds = '0' + seconds
            }
            if (minutes < 10) {
                minutes = '0' + minutes
            }
            // Render a countdown
            return <span>{minutes}:{seconds}</span>
        }
    }

    const upcomingChatCountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <></>
        } else {
            if (days > 0) {
                return days + ' days'
            }
            if (days === 0 && hours > 0) {
                return hours + ' hours, ' + minutes + ' mins'
            }
            if (days === 0 && hours === 0 && minutes > 0) {
                return minutes + ' mins'
            }
            if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
                return seconds + ' seconds'
            }
        }
    }

    const onEndTimeWithDelayCompleteTimer = () => {
        if (auth.appSettings.chat_settings.disabled_normal_chat === true) {
            setCanChat(false)
        }
    }

    const renderCountDown = () => {
        return <div>
            <Countdown
                date={chatEndTime}
                onComplete={onEndTimeCompleteTimer}
                renderer={renderer}
            />
        </div>
    }

    return (
        <>
            {isAdmin === false && isLive === true && streamJoined === false &&
                <Button
                    onClick={joinStream}>
                    Join Stream
                </Button>
            }
            {['admin', 'content_manager'].includes(role) && showViewGoLiveButton === true &&
                <Link target='_blank' rel='noreferrer' to={`/admin/live-stream-sessions/${receiverId}`}>
                    <Button>
                        View Go Live Stream
                    </Button>
                </Link>
            }
            {streamToken !== '' && isAdmin === true && (['model', 'live_stream_manager'].includes(role)) && canModelGoLive === true &&
                <StreamWrapper streamToken={streamToken} onGoLive={goLive} onStopLive={stopLive} tabToken={tabToken} />
            }
            {room !== '' && pubId !== '' && pubPvtId !== '' && streamToken !== '' && isAdmin === false && isLive === true && streamJoined === true &&
                <StreamWrapper streamToken={streamToken} room={room} pubId={pubId} pubPvtId={pubPvtId} stopShowStream={stopShowStream} />
            }
            {/* <ViewAllowCheckBoxDiv
                id='extra'
                className='row'
                isModel={role === 'model'}
                isViewSession={(role === 'admin' || role === 'content_manager' || role === 'sub_admin') && showViewGoLiveButton === true}
                isJoinStreamShow={isAdmin === false && isLive === true && streamJoined === false ? true : false}
            >
                {showAllowUserToChatCheckBox &&
                    <div className='col-12 text-md-right ml-md-auto'>
                        <div className='col-12'>
                            <div className='form-check'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='allow-user-to-chat'
                                    name='AllowUserToChat'
                                    checked={allowUserToChat}
                                    onChange={(e) => onChangeAllowUserToChat(e.target.checked)}
                                />
                                <label className='form-check-label' htmlFor='allow-user-to-chat'>
                                    Allow User To Chat
                                </label>
                            </div>
                        </div>
                    </div>
                }
                <div className='col-12 ml-md-auto m-auto mr-sm-1'>
                    <div className='col-12'>
                        {chatStartTime !== null && chatEndTime === null &&
                            <div className='text-md-right'>
                                Next {meetingWord} starts in: &nbsp;&nbsp;
                                <Countdown date={chatStartTime}
                                    onComplete={onCompleteTimer}
                                    renderer={upcomingChatCountdownRenderer}
                                />
                                <br />
                                Start time : {meetingStartTime}
                            </div>
                        }
                    </div>
                    {canChat &&
                        <div className='col-12'>
                            {chatEndTime !== null &&
                                <div className='d-md-flex d-flex justify-content-center justify-content-md-end'>
                                    <span className='mr-2' style={{ color: '#C6B2B5', display: 'flex', alignItems: 'center' }}>Time Remaining</span>
                                    <div style={{ width: '66px', height: '66px', background: '#F75D78', display: 'flex', borderRadius: '50%', justifyContent: 'center', alignItems: 'center' }}>
                                        <CountdownCircleTimer
                                            {...timerProps}
                                            duration={countDownDuration}
                                            colors={[['#FFF']]}
                                            initialRemainingTime={countDownRemainingTime}
                                        >
                                            {() =>
                                                renderCountDown()
                                            }
                                        </CountdownCircleTimer>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {chatEndTimeWithDelay !== null &&
                        <div style={{ display: 'none' }}>
                            <Countdown
                                date={chatEndTimeWithDelay}
                                onComplete={onEndTimeWithDelayCompleteTimer}
                            />
                        </div>
                    }
                </div>
            </ViewAllowCheckBoxDiv> */}
        </>
    )
}

LiveStream.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    chatUserProfileInfoAction: PropTypes.func.isRequired,
    setChatId: PropTypes.func.isRequired,
    setSelectedChatModelId: PropTypes.func.isRequired,
    addToken: PropTypes.func.isRequired,
    removeToken: PropTypes.func.isRequired,
    readMessage: PropTypes.func.isRequired,
    openCopyToClipboardToast: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    deleteMessageAction: PropTypes.func.isRequired,
    addNewMessageInEnd: PropTypes.func.isRequired,
    getUserMessages: PropTypes.func.isRequired,
    showBootstrapNavbar: PropTypes.func.isRequired,
    getUserSendMessageStatus: PropTypes.func.isRequired,
    setAllowUserToChat: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat
})

export default connect(
    mapStateToProps, {
    chatUserProfileInfoAction,
    setChatId,
    setSelectedChatModelId,
    addToken,
    removeToken,
    readMessage,
    openCopyToClipboardToast,
    setSweetAlert,
    deleteMessageAction,
    addNewMessageInEnd,
    getUserMessages,
    showBootstrapNavbar,
    getUserSendMessageStatus,
    setAllowUserToChat,
    updateMessageAction
}
)(withRouter(LiveStream))