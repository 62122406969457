import {
    CHAT_USER_LIST,
    IS_LOADING,
    SET_MESSAGES,
    CHAT_USER_PROFILE_INFO,
    TIPS_LIST,
    IS_MORE_MESSAGE_LOADING,
    LOAD_MORE_MESSAGE,
    SET_CHAT_SELECTED_ID,
    SET_CHAT_MODEL_ID,
    ADD_NEW_MESSAGE_IN_END,
    UPDATE_MESSAGE,
    DELETE_MESSAGE,
    TOGGLE_CHAT_TIP_POPUP,
    UPDATE_USER_AMOUNT_SPENT,
    UPDATE_USER_LAST_SEEN,
    UPDATE_USER_NICKNAME,
    SET_ROTATE_CHAT_IMAGE,
    IS_MESSAGES_LOADING,
    SET_IS_CHAT_SCREEN_OPEN,
    IS_READ_MESSAGE_COUNT,
    UPDATE_USER_DETAILS,
    SHOW_BOOTSTRAP_NAVBAR
} from './../actions/types'

const initialState = {
    isAdmin: false,
    userInfo: {},
    messages: [],
    shouldLoadMessages: false,
    userList: [],
    isLoading: false,
    totalPages: 0,
    currentPage: 0,
    tipsList: [],
    totalTipsPages: 0,
    currentTipsPage: 0,
    userProfileInfo: {},
    sortBy: '',
    isMoreMessageLoading: false,
    totalMessagesPage: 0,
    currentMessagesPage: 0,
    selectedUserId: '',
    selectedModelId: '',
    isLiveStreamAvailable: false,
    sortBySubscribers: '',
    shouldLoadMoreMessages: false,
    showChatTipPopup: false,
    isMessagesLoading: false,
    isChatScreenOpen: false,
    showBootstrapNavbar: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHAT_USER_LIST:
            return {
                ...state,
                userList: action.payload.isInfiniteScroll
                    ? [...state.userList, ...action.payload.userList]
                    : [...action.payload.userList],
                totalPages: action.payload.totalPages,
                currentPage: parseInt(action.payload.currentPage, 10),
                sortBy: action.payload.sortBy,
                sortBySubscribers: action.payload.sortBySubscribers,
                isLoading: false
            }
        case UPDATE_USER_DETAILS:
            return {
                ...state,
                userList: action.payload
            }
        case TIPS_LIST:
            return {
                ...state,
                tipsList: action.payload.tipsList,
                totalTipsPages: action.payload.totalPages,
                currentTipsPage: action.payload.currentPage,
                isLoading: false
            }
        case CHAT_USER_PROFILE_INFO: {
            let newProfiles = Object.assign(state.userProfileInfo)
            newProfiles[action.payload._id] = {
                profile: action.payload.avatarUrl,
                name: action.payload.name,
                nick_name: action.payload.nick_name,
                totalAmountSpent: action.payload.total_amount_spent,
                isLiveStreamAvailable: action.payload.isLiveStreamAvailable,
                totalRefundAmount: action.payload.total_refund_amount !== undefined ? action.payload.total_refund_amount : 0.00,
                totalVoidAmount: action.payload.total_void_amount !== undefined ? action.payload.total_void_amount : 0.00,
                totalChargeBackAmount: action.payload.total_chargeback_amount !== undefined ? action.payload.total_chargeback_amount : 0.00,
                totalChargeBackCount: action.payload.total_chargeback_count !== undefined ? action.payload.total_chargeback_count : 0.00,
                lastSeen: action.payload.last_seen,
                wallet_amount: action.payload.wallet_amount
            }
            return {
                ...state,
                userProfileInfo: newProfiles
            }
        }
        case UPDATE_USER_NICKNAME: {
            let newProfiles = state.userProfileInfo
            if (newProfiles[action.payload.userId] !== undefined) {
                newProfiles[action.payload.userId] = {
                    ...newProfiles[action.payload.userId],
                    nick_name: action.payload.nickName
                }
            }
            return {
                ...state,
                userProfileInfo: newProfiles
            }
        }
        case IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case IS_MORE_MESSAGE_LOADING:
            return {
                ...state,
                isMoreMessageLoading: true
            }
        case SET_MESSAGES:
            return {
                ...state,
                messages: action.payload.messages,
                totalMessagesPage: action.payload.totalPages,
                currentMessagesPage: action.payload.currentPage,
                shouldLoadMoreMessages: action.payload.shouldLoadMoreMessages
            }
        case LOAD_MORE_MESSAGE: {
            let messages = []
            for (let i = 0; i < action.payload.messages.length; i++) {
                const resElement = action.payload.messages[i]

                for (let j = 0; j < state.messages.length; j++) {
                    const element = state.messages[j]

                    if (element._id !== resElement._id) {
                        messages.push(resElement)
                        break
                    }
                }
            }
            let allMessages = messages.concat(state.messages)

            return {
                ...state,
                messages: allMessages,
                totalMessagesPage: action.payload.totalPages,
                currentMessagesPage: action.payload.currentPage,
                isMoreMessageLoading: false,
                shouldLoadMoreMessages: action.payload.shouldLoadMoreMessages
            }
        }
        case SET_CHAT_SELECTED_ID:
            return {
                ...state,
                selectedUserId: action.payload
            }
        case SET_CHAT_MODEL_ID:
            return {
                ...state,
                selectedModelId: action.payload
            }
        case ADD_NEW_MESSAGE_IN_END: {
            let messages = [...state.messages]
            let message = messages.filter((msg) => msg._id === action.payload._id)
            let existMessage = messages.filter((msg) => msg._id === action.payload.old_message_id)

            // Update message if processing is true
            if (message.length > 0 && message[0].processing === true && action.payload.processing === false) {
                let copyMessage = [...state.messages]
                const index = copyMessage.findIndex((msg) => msg._id === action.payload._id)
                if (index > -1) {
                    copyMessage.splice(index, 1)
                }
                copyMessage.push(action.payload)
                return {
                    ...state,
                    messages: copyMessage
                }
            }
            // Update message while message unlock by model
            if (existMessage.length > 0) {
                let copyMessage = [...state.messages]
                const index = copyMessage.findIndex((msg) => msg._id === action.payload.old_message_id)
                if (index > -1) {
                    copyMessage.splice(index, 1)
                }
                copyMessage.push(action.payload)
                return {
                    ...state,
                    messages: copyMessage
                }
            }
            // If processing is false don't update the messages
            if (message.length > 0) {
                return {
                    ...state
                }
            }
            // If message is not found in props add new message at the end
            return {
                ...state,
                messages: [...state.messages, action.payload]
            }
        }
        case UPDATE_MESSAGE: {
            const messages = [...state.messages]
            for (let index = 0; index < messages.length; index++) {
                let message = messages[index]

                if (message._id === action.payload._id.toString()) {
                    messages[index] = action.payload
                }
            }
            return {
                ...state,
                messages: messages
            }
        }
        case DELETE_MESSAGE: {
            let copyMessage = [...state.messages]
            const index = copyMessage.findIndex((msg) => msg._id === action.payload._id)
            if (index > -1) {
                copyMessage.splice(index, 1)
            }
            return {
                ...state,
                messages: copyMessage
            }
        }
        case TOGGLE_CHAT_TIP_POPUP: {
            return {
                ...state,
                showChatTipPopup: action.payload
            }
        }
        case UPDATE_USER_LAST_SEEN: {
            let userDetails = state.userProfileInfo
            let userId = action.payload
            userDetails[userId].lastSeen = new Date()
            return {
                ...state,
                userProfileInfo: userDetails
            }
        }
        case UPDATE_USER_AMOUNT_SPENT: {
            let userDetails = state.userProfileInfo
            let userList = state.userList
            const userId = action.payload.userId
            const userIndex = userList.findIndex((user) => user._id === userId)
            if (userIndex !== -1) {
                userList[userIndex].total_amount_spent = action.payload.totalAmountSpent
                userList[userIndex].wallet_amount = action.payload.walletAmount
            }
            if (userDetails[userId] !== undefined) {
                userDetails[userId].totalAmountSpent = action.payload.totalAmountSpent
                userDetails[userId].wallet_amount = action.payload.walletAmount
            }
            return {
                ...state,
                userProfileInfo: userDetails,
                userList: userList
            }
        }
        case SET_ROTATE_CHAT_IMAGE: {
            const imageRotate = action.payload
            const userIndex = state.messages.findIndex((messages) => messages._id === imageRotate._id)
            state.messages[userIndex].media = imageRotate.media
            if (state.messages[userIndex].url !== undefined) {
                state.messages[userIndex].url = imageRotate.url
            }
            return {
                ...state,
                messages: state.messages
            }
        }
        case IS_MESSAGES_LOADING:
            return {
                ...state,
                isMessagesLoading: action.payload
            }
        case SET_IS_CHAT_SCREEN_OPEN:
            return {
                ...state,
                isChatScreenOpen: action.payload
            }
        case IS_READ_MESSAGE_COUNT:
            const updatedUserList = state.userList.map(user =>
                user._id === action.payload.userId
                    ? {
                        ...user,
                        unreadcount: {
                            ...user.unreadcount,
                            model_unread_message: 0
                        }
                    }
                    : user
            )
            return {
                ...state,
                userList: updatedUserList,
                totalPages: state.totalPages,
                currentPage: parseInt(state.currentPage, 10),
                sortBy: state.sortBy,
                sortBySubscribers: state.sortBySubscribers,
                isLoading: false
            }
        case SHOW_BOOTSTRAP_NAVBAR: {
            return {
                ...state,
                showBootstrapNavbar: action.payload
            }
        }
        default:
            return state
    }
}
