import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CustomChatInput = styled.textarea`
    width: 100%;
    overflow: hidden;
    resize: none;
    height: 34px;
    border: 1px solid ${props => props.usedInModal === 'true' ? 'lightgrey !important' : props.borderColor};
    border-radius: 10px;
	font-size: 14px !important;
    padding: ${props => props.usedInModal === 'true' ? '11px 16px !important' : '6px 8px'};

    :focus {
        outline: ${props => props.usedInModal === 'true' ? 'auto' : 'none'};
    }
`

const ChatInput = (props) => {
    const { auth, value, setValue, disabled, usedInModal } = props
    const classes = usedInModal ? 'form-control' : ''
    const {
        content_color
    } = auth.appSettings

    const textareaRef = useRef(null)

    function calculateVisibleRows() {
        const textarea = document.getElementById('custom-chat-input')
        const lineHeight = parseFloat(window.getComputedStyle(textarea).lineHeight)
        const visibleRows = Math.floor(textarea.scrollHeight / lineHeight)
        return visibleRows
    }

    useEffect(() => {
        // Adjust the height of the textarea to fit the content
        if (textareaRef.current) {
            const visibleRows = calculateVisibleRows()
            if (visibleRows <= 5) {
                textareaRef.current.style.overflow = 'hidden'
                textareaRef.current.style.height = 'auto'
                let scrollHeight = textareaRef.current.scrollHeight
                if (scrollHeight) {
                    if (scrollHeight < 34) scrollHeight = 34
                    textareaRef.current.style.height = `${scrollHeight}px`
                }
            } else {
                textareaRef.current.style.overflow = 'scroll'
            }
        }
    }, [value])

    return (
        <CustomChatInput
            id='custom-chat-input'
            ref={textareaRef}
            value={value}
            onChange={(e) => { setValue(e.target.value) }}
            borderColor={props.borderColor || content_color}
            rows={1}
            disabled={disabled}
            placeholder='Write a message...'
            className={classes}
            usedInModal={usedInModal ? 'true' : 'false'}
        />
    )
}

ChatInput.propTypes = {
    auth: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    usedInModal: PropTypes.bool,
    borderColor: PropTypes.string
}

export default ChatInput
