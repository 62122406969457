import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import WebsiteFontColor from './../Layouts/WebsiteFontColor'
import styled from 'styled-components'
import { FILE_SIZE_LIMIT_IN_BYTE } from '../../utils/constant'
import { setSweetAlert } from '../../actions/sweetAlertActions'
import FullScreenModelPopUpDialog from '../Layouts/FullScreenModelPopUpDialog'
import { getFileExtension, sortArrayById } from '../../utils/common'
import _ from 'lodash'
import { sendMassMessage, getUserList } from '../../actions/chatActions'
import axios from 'axios'
import { getPresignedUrl } from '../../utils/getPresignedUrl'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import AlarmIcon from '@material-ui/icons/Alarm'
import moment from 'moment'
import PreviewContent from './../content/PreviewContent'
import MediaSelectionPopup from '../media/MediaSelectionPopup'
import { updateMediaCountInCategory } from '../../actions/mediaAction'
import { MP4, MOV } from '../../utils/constant'

const useStyles = () => ({
    selectForm: {
        padding: '5px',
        height: '40px',
        border: 'none'
    }
})

const ButtonGroup = styled.div`
    width:100%;

    button.btn.mb-2.p-2.shadow-none.m-0:hover {
        background-color: ${props => props.content_color};
        color: ${props => props.content_font_color} !important;
        outline: 1px solid ${props => props.content_color} !important;
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.m-0.border-2:focus {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.selectedOption {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 5px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.shadow-none.m-0:focus-visible, button.btn.mb-2.p-2.shadow-none.m-0:focus {
        outline: transparent !important;
    }

    button.rounded-start {
        border-bottom-left-radius: .25rem !important;
        border-top-left-radius: .25rem !important;
    }

    button.rounded-end {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important;
    }

    @media (max-width: 1279px) and (min-width: 991px) {
        button.btn.mb-2.p-2.shadow-none.m-0 {
            font-size: 0.7125rem;
        }
    }
`

const HelperButton = styled.button`
    border-radius: 25px;
    border: none;
    font-size: 20px;
    vertical-align: middle;
`

const DatePickerDiv = styled.div`
    .react-datepicker__input-time-container .react-datepicker-time__input-container
    .react-datepicker-time__input input {
        width: auto;
    }
    .react-datepicker-wrapper {
        width:100%
    }
    .react-datepicker-input {
        text-align: center
    }
`

function NewMassMsgDialog(props) {
    const { content_color, content_font_color, site_font_color, button_shadow_color, card_background_color, content_unlock_minimum_amount, content_unlock_maximum_amount } = props.auth.appSettings
    const [media, setMedia] = useState([])
    const [mediaPreview, setMediaPreview] = useState([])
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [type, setType] = useState('')
    const [uploadProgress, setUploadProgress] = useState({ type: '' })
    const [disableButtons, setDisableButtons] = useState(false)
    const [isPreviewModeEnable, setIsPreviewModeEnable] = useState(false)
    const [option, setOption] = useState('locked')
    const [amount, setAmount] = useState(20)
    const [message, setMessage] = useState('')
    const [helperModelOpen, setHelperModelOpen] = useState(false)
    const [sendTo, setSendTo] = useState('EVERYONE')
    const [newUserMessageDelay, setNewUserMessageDelay] = useState('IMMEDIATELY')
    const [helperModelFutureOpen, setHelperModelFutureOpen] = useState(false)
    const [isSchedule, setIsSchedule] = useState(false)
    const [schedulePostTime, setSchedulePostTime] = useState(new Date().setDate(new Date().getDate() + 1))
    const [schedulePostHour, setSchedulePostHour] = useState((new Date().getHours() + 24) % 12 || 12)
    const [schedulePostMin, setSchedulePostMin] = useState(new Date().getMinutes())
    const [schedulePostTimeFormat, setSchedulePostTimeFormat] = useState(new Date().getHours() > 12 ? 'PM' : 'AM')
    const [sendToHour, setSendToHour] = useState(2)
    const [messageType, setMessageType] = useState('text')
    const [isRearrangeModeEnable, setIsRearrangeModeEnable] = useState(false)
    const [isRearrangeModeEnableForMedia, setIsRearrangeModeEnableForMedia] = useState(false)
    const [dragId, setDragId] = useState('')
    const [isSelectedContentManager, setIsSelectedContentManager] = useState(true)
    const [showMediaSelectionPopup, setShowMediaSelectionPopup] = useState(false)
    const [showMediaPreviewSelectionPopup, setShowMediaPreviewSelectionPopup] = useState(false)
    const [selectedMediaId, setSelectedMedia] = useState([])
    const [selectedPreviewMediaId, setSelectedPreviewMedia] = useState([])
    const [contentManagerMedia, setContentManagerMedia] = useState([])
    const [isPreviewModeEnableForMedia, setIsPreviewModeEnableForMedia] = useState(false)
    const [contentMediaPreview, setContentMediaPreview] = useState([])
    const [mediaMessageType, setMessageMediaType] = useState('text')
    const [selectedCategory, setSelectedCategory] = useState(null)
    const { allMedia, category } = props.media
    const messageFormat = isSelectedContentManager ? mediaMessageType : messageType


    const galleryFilesFromPreview = (files) => {
        if (mediaPreview.length === 0) {
            galleryFiles([files[0]], 'preview')
        } else {
            return props.setSweetAlert({ description: 'You can not add more than one preview.' })
        }
    }

    const galleryFilesFromOriginal = (files) => {
        galleryFiles(files, 'original')
    }

    const galleryFiles = (files, name) => {
        if (isRearrangeModeEnable !== true) {
            const existFilesCount = isSelectedContentManager ? contentManagerMedia.length : media.length
            const uploadFileCount = 45 - existFilesCount < files.length ? 45 - existFilesCount : files.length
            const totalFilesCount = files.length + existFilesCount
            let type = 'gallery'
            for (let index = 0; index < uploadFileCount; index++) {
                const acceptExtension = ['video/mp4', 'video/quicktime', 'image/jpeg', 'image/jpeg', 'image/png']
                if (!acceptExtension.includes(files[index].type)) {
                    return props.setSweetAlert({ description: 'Media format is not supported.' })
                }
                if (totalFilesCount === 1) {
                    if (['video/mp4', 'video/quicktime'].includes(files[index].type)) {
                        type = 'video'
                    } else if (['image/jpeg', 'image/jpeg', 'image/png'].includes(files[index].type)) {
                        type = 'photo'
                    }
                }
            }
            const newUploadedFiles = []
            let mediaUploadFiles = []
            for (let index = 0; index < uploadFileCount; index++) {
                const fileSize = _.get(files[index], 'size', -1)
                if (fileSize !== -1 && fileSize > FILE_SIZE_LIMIT_IN_BYTE) {
                    props.setSweetAlert({ description: `File ${files[index].name} size is too large.` })
                } else {
                    const file = files[index]
                    if (file) {
                        const newFileName = file.name
                        const newFileSize = file.size
                        const galleries = (name === 'original') ? media : mediaPreview
                        let findRecord = -1
                        if (name === 'original') {
                            findRecord = _.findIndex(galleries, function (n) {
                                return (n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                            })
                        }
                        if (findRecord === -1) {
                            let newFile = {
                                selectedFile: file,
                                renderFile: isSelectedContentManager ? file.path : URL.createObjectURL(file)
                            }
                            if (name === 'original') {
                                if (isSelectedContentManager) {
                                    const fileIndex = mediaUploadFiles.findIndex(obj => obj.selectedFile.id === file.id)
                                    if (fileIndex !== -1) {
                                        return
                                    } else {
                                        // File does not exist, add it
                                        mediaUploadFiles.push(newFile)
                                    }
                                } else {
                                    newUploadedFiles.push(newFile)
                                }
                            } else {
                                isSelectedContentManager ? setContentMediaPreview([newFile]) : setMediaPreview([newFile])
                            }
                        }
                    }
                }
            }
            if (totalFilesCount > 45) {
                props.setSweetAlert({ description: `You have selected ${totalFilesCount} images, maximum 45 images allowed.` })
            } else {
                if (name === 'original') {
                    isSelectedContentManager ? setMessageMediaType(type) : setMessageType(type)
                    const filesToCopy = isSelectedContentManager ? [] : [...media]
                    const filesToConcat = isSelectedContentManager ? mediaUploadFiles : newUploadedFiles
                    isSelectedContentManager ? setContentManagerMedia([...filesToCopy, ...filesToConcat]) : setMedia([...filesToCopy, ...filesToConcat])
                }
            }
        }
    }

    const handleAddFile = (file, name) => {
        if (file) {
            const newFileName = file.name
            const newFileSize = file.size
            const galleries = (name === 'original') ? media : mediaPreview
            let findRecord = -1
            if (name === 'original') {
                findRecord = _.findIndex(galleries, function (n) {
                    return (n.selectedFile.name === newFileName && n.selectedFile.size === newFileSize) ? n : false
                })
            }
            if (findRecord === -1) {
                let newFile = {
                    selectedFile: file,
                    renderFile: URL.createObjectURL(file)
                }
                if (name === 'original') {
                    const mediaData = isSelectedContentManager ? contentManagerMedia : media
                    const copy = JSON.parse(JSON.stringify(mediaData))
                    copy.push(newFile)
                    isSelectedContentManager ? setContentManagerMedia(copy) : setMedia(copy)
                } else {
                    isSelectedContentManager ? setContentMediaPreview([newFile]) : setMediaPreview([newFile])
                }
            }
        }
    }

    const openDialog = (url, type) => {
        if (disableButtons === true) {
            return
        }
        if (!isDialogOpen === true) {
            document.querySelector('body').style.overflow = 'hidden'
        } else {
            document.querySelector('body').style.overflow = 'visible'
        }
        setUrl(url)
        setType(type)
        setIsDialogOpen(!isDialogOpen)
    }

    const uploadProgressInMb = (galleryIndex, progressNumber) => {
        let actualFileSize = 0
        let actualFileUploaded = '0'
        const type = _.get(uploadProgress, 'type', '')
        const selectedFile = type === 'original' ? media[galleryIndex].selectedFile : mediaPreview[galleryIndex].selectedFile
        actualFileSize = (_.get(selectedFile, 'size', 0) / 1024) / 1024
        actualFileUploaded = ((actualFileSize * progressNumber) / 100).toFixed(2)

        return <div className='progress-text' style={{ color: site_font_color }}>Uploading {actualFileUploaded} MB Of {actualFileSize.toFixed(2)} MB</div>
    }

    const handleDeletePhoto = (index, name) => {
        if (name === 'preview') {
            if (isSelectedContentManager) {
                setContentMediaPreview([])
                setSelectedPreviewMedia([])
            } else {
                setMediaPreview([])
            }
        } else {
            const existingMedia = isSelectedContentManager ? contentManagerMedia : media
            if (existingMedia.length === 2) {
                isSelectedContentManager ? setIsRearrangeModeEnableForMedia(false) : setIsRearrangeModeEnable(false)
            }
            const copyMedia = [...existingMedia]
            const updatedFilesArray = [
                ...copyMedia.slice(0, index),
                ...copyMedia.slice(index + 1)
            ]
            if (updatedFilesArray.length === 0 && mediaPreview.length === 0) {
                isSelectedContentManager ? setMessageMediaType('text') : setMessageType('text')
            } else {
                let type = 'gallery'
                if (updatedFilesArray.length === 1) {
                    if (['video/mp4', 'video/quicktime'].includes(updatedFilesArray[0].selectedFile.type)) {
                        type = 'video'
                    } else if (['image/jpeg', 'image/jpeg', 'image/png'].includes(updatedFilesArray[0].selectedFile.type)) {
                        type = 'photo'
                    }
                }
                isSelectedContentManager ? setMessageMediaType(type) : setMessageType(type)
            }
            // update list of selected id from media while remove selected media in the mass message popup
            isSelectedContentManager && setSelectedMedia(updatedFilesArray.map(obj => obj.selectedFile.id))
            isSelectedContentManager ? setContentManagerMedia(updatedFilesArray) : setMedia(updatedFilesArray)
        }
    }

    const reArrangeImage = (dragIndex, dropIndex) => {
        const targetMedia = isSelectedContentManager ? contentManagerMedia : media
        let copyNewFiles = [...targetMedia]
        const draggedImage = copyNewFiles[dragIndex]
        copyNewFiles[dragIndex] = copyNewFiles[dropIndex]
        copyNewFiles[dropIndex] = draggedImage
        isSelectedContentManager ? setContentManagerMedia(copyNewFiles) : setMedia(copyNewFiles)
    }

    const handleDrag = (index) => {
        if (isRearrangeModeEnable === true || isRearrangeModeEnableForMedia) {
            setDragId(index)
        }
    }

    const handleDrop = (index) => {
        if ((isRearrangeModeEnable === true || isRearrangeModeEnableForMedia) && dragId !== '') {
            const targetMedia = isSelectedContentManager ? contentManagerMedia : media
            const copyNewFiles = [...targetMedia]
            const dragFile = copyNewFiles[dragId]
            copyNewFiles.splice(dragId, 1)
            copyNewFiles.splice(index, 0, dragFile)
            isSelectedContentManager ? setContentManagerMedia(copyNewFiles) : setMedia(copyNewFiles)
            isSelectedContentManager && setSelectedMedia(copyNewFiles.map(obj => obj.selectedFile.id))
            setDragId('')
        }
        setDragId('')
    }

    const handleGalleryChange = (e, name) => {
        const files = e.target.files
        if (name === 'preview') {
            if (files.length > 1) {
                return props.setSweetAlert({ description: 'You can not add more than one preview.' })
            } else {
                handleAddFile(files[0], name)
            }
        } else {
            galleryFiles(files, name)
        }
    }

    const uploadFileUsingPresignedUrl = async (contentType, url, body, galleryIndex = 0, type = 'original') => {
        const cancelTokenSource = axios.CancelToken.source()
        const config = {
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                window.addEventListener('offline', function () {
                    cancelTokenSource.cancel('Network error')
                    setDisableButtons(false)
                    return props.setSweetAlert({ description: 'Seems you are offline. Please check your internet connection and post again.' })
                })

                const object = {
                    progress: percentCompleted + '%',
                    index: galleryIndex,
                    progressNumber: percentCompleted,
                    type: type
                }
                setUploadProgress(object)
            },
            cancelToken: cancelTokenSource.token
        }
        const axiosInstance = axios.create()
        delete axiosInstance.defaults.headers.common['Authorization']
        axiosInstance.defaults.headers['Content-Type'] = contentType === 'video' ? 'video/mp4' : 'image/jpg'
        let apiResponse = await axiosInstance.put(url, body, config)
        if (apiResponse.status === 200) {
            return true
        }
        return false
    }

    const sendMassMessage = async (e) => {
        e.preventDefault()
        if (message.trim() === '' && messageFormat === 'text') {
            return props.setSweetAlert({ description: 'Please insert the message.' })
        }
        const mediaData = isSelectedContentManager ? contentManagerMedia : media
        if (messageFormat !== 'text' && mediaData.length < 1) {
            return props.setSweetAlert({ description: 'Please Select Media' })
        }

        const previewMedia = isSelectedContentManager ? selectedPreviewMediaId : mediaPreview
        if (previewMedia.length > 1) {
            return props.setSweetAlert({ description: 'You can only select 1 preview content' })
        }
        if (option === 'locked') {
            if (mediaData.length < 1) {
                return props.setSweetAlert({ description: 'Please Select Media' })
            }
            if (!((content_unlock_minimum_amount <= amount) && (amount <= content_unlock_maximum_amount))) {
                return props.setSweetAlert({ description: `Choose an amount between ${content_unlock_minimum_amount} and ${content_unlock_maximum_amount}` })
            }
        }

        if (_.isEmpty(selectedCategory) === false) {
            const slug = selectedCategory.value.trim().toLowerCase()
            const isNew = _.get(selectedCategory, '__isNew__', false)
            let errorMessage = ''
            if (slug === '') {
                errorMessage = 'Category not allow empty'
            } else if (isNew) {
                if (slug.length > 30) {
                    errorMessage = 'Category name cannot be more than 30 characters.'
                }
                const isCategoryExist = category.find(item => item.slug === slug)
                if (isCategoryExist !== undefined) {
                    errorMessage = 'Category already exist.'
                }
            }
            if (!_.isEmpty(errorMessage)) {
                return props.setSweetAlert({ description: errorMessage })
            }
        }

        const obj = {
            message,
            media: [],
            media_preview: [],
            sender: props.chat.selectedModelId,
            type: messageFormat,
            fromAdmin: true,
            isRead: 0,
            processing: messageFormat === 'text' ? false : true,
            isLocked: option,
            amount: amount,
            send_to: sendTo,
            isSelectedContentManager: isSelectedContentManager
        }
        let contentLeftForProcessing = 0
        let schedulePostTimes = new Date()
        if (isSchedule === true && sendTo !== 'ONLY_NEW') {
            schedulePostTimes = new Date(schedulePostTime)
            let hourWithFormat = schedulePostHour + '' + schedulePostTimeFormat
            let hour = moment(hourWithFormat, ['HHA']).format('HH')
            schedulePostTimes.setHours(hour)
            schedulePostTimes.setMinutes(schedulePostMin)
        }
        obj.created = schedulePostTimes
        if (sendTo !== 'ONLY_EXISTING') {
            obj.new_user_message_delay = newUserMessageDelay
            if (newUserMessageDelay === 'DELAY_BY_HOURS') {
                obj.delay_by_hours = Number(sendToHour).toFixed(1)
            }
        }
        if (!isSelectedContentManager) {
            for (let index = 0; index < media.length; index++) {
                const element = media[index]
                let fileExtension = getFileExtension(element.selectedFile.name)
                fileExtension = fileExtension.toUpperCase()
                let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG', 'MP4', 'MOV']
                if (!acceptedFileExtensions.includes(fileExtension)) {
                    return props.setSweetAlert({ description: `Image Number ${index + 1} media format is not supported.` })
                }
            }

            for (let index = 0; index < mediaPreview.length; index++) {
                const element = mediaPreview[index]
                let fileExtension = getFileExtension(element.selectedFile.name)
                fileExtension = fileExtension.toUpperCase()
                let acceptedFileExtensions = ['PNG', 'JPG', 'JPEG', 'MP4', 'MOV']
                if (!acceptedFileExtensions.includes(fileExtension)) {
                    return props.setSweetAlert({ description: 'Preview media format is not supported.' })
                }
            }
        }

        setDisableButtons(true)

        if (!isSelectedContentManager) {
            for (let index = 0; index < media.length; index++) {

                const element = media[index]

                let fileExtension = getFileExtension(element.selectedFile.name)
                const fileExtensionToLower = fileExtension.toLowerCase()
                let fileExtensionForPresignedUrl = 'photo'
                if ([MP4, MOV].includes(fileExtensionToLower)) {
                    fileExtensionForPresignedUrl = 'video'
                }

                props.updateMediaCountInCategory({ slug: 'all media', mediaType: fileExtensionForPresignedUrl })
                let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'message', fileExtensionForPresignedUrl)
                if (presignedUrlData.presigned_url !== '') {
                    let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index)
                    if (uploadFile === true) {
                        obj.media.push({
                            url: presignedUrlData.file_name,
                            thumbnail_url: '',
                            blur_url: '',
                            is_process: true,
                            content_type: fileExtensionForPresignedUrl,
                            is_error: false
                        })
                        contentLeftForProcessing++
                    } else {
                        setDisableButtons(false)
                        return props.setSweetAlert({ description: presignedUrlData.message })
                    }
                } else {
                    setDisableButtons(false)
                    return props.setSweetAlert({ description: presignedUrlData.message })
                }
            }
        }

        // preview image upload
        if (!isSelectedContentManager) {
            for (let index = 0; index < mediaPreview.length; index++) {
                const element = mediaPreview[index]

                let fileExtension = getFileExtension(element.selectedFile.name)
                let fileExtensionForPresignedUrl = 'photo'
                const fileExtensionToLower = fileExtension.toLowerCase()
                if ([MP4, MOV].includes(fileExtensionToLower)) {
                    fileExtensionForPresignedUrl = 'video'
                }

                props.updateMediaCountInCategory({ slug: 'all media', mediaType: fileExtensionForPresignedUrl })
                props.updateMediaCountInCategory({ slug: 'preview', mediaType: fileExtensionForPresignedUrl })
                let presignedUrlData = await getPresignedUrl(element.selectedFile.name, 'message', fileExtensionForPresignedUrl)
                if (presignedUrlData.presigned_url !== '') {
                    let uploadFile = await uploadFileUsingPresignedUrl(fileExtensionForPresignedUrl, presignedUrlData.presigned_url, element.selectedFile, index, 'preview')
                    if (uploadFile === true) {
                        obj.media_preview.push({
                            url: presignedUrlData.file_name,
                            thumbnail_url: '',
                            is_process: true,
                            content_type: fileExtensionForPresignedUrl
                        })
                        contentLeftForProcessing++
                    } else {
                        setDisableButtons(false)
                        return props.setSweetAlert({ description: presignedUrlData.message })
                    }
                } else {
                    setDisableButtons(false)
                    return props.setSweetAlert({ description: presignedUrlData.message })
                }
            }
        }
        if (isSelectedContentManager) {
            obj.contentLeftForProcessing = 0
            obj.media_preview_item = selectedPreviewMediaId.length > 0 ? selectedPreviewMediaId[0] : ''
            obj.media_item = selectedMediaId
            obj.processing = false
        } else {
            obj.contentLeftForProcessing = contentLeftForProcessing
            obj.category = selectedCategory
        }
        const isSuccess = await props.sendMassMessage(obj)
        let data = {
            pageNum: props._pageNum,
            sortBy: props._sortBy,
            userName: props._userName,
            sortBySubscribers: props._sortBySubscribers

        }
        props.getUserList(data)
        setDisableButtons(false)
        props.handleClose()
        if (isSuccess === true) {
            props.setSweetAlert({ description: 'The mass message is being sent and will reach all users shortly.' })
        }
        return
    }

    const onChangePreviewMode = () => {
        if (isSelectedContentManager) {
            if (isPreviewModeEnableForMedia === true) {
                setContentMediaPreview([])
                setSelectedPreviewMedia([])
            }
            setIsPreviewModeEnableForMedia(!isPreviewModeEnableForMedia)
        } else {
            if (isPreviewModeEnable === true) {
                setMediaPreview([])
            }
            setIsPreviewModeEnable(!isPreviewModeEnable)
        }
    }

    const handleSendOptionChange = (e) => {
        setSendTo(e.target.value)
        setNewUserMessageDelay('STAGGER_OVER_TIME')
    }

    const handleMedia = (selectedMediaIds, name) => {
        if (name === 'preview') {
            setShowMediaPreviewSelectionPopup(false)
            if (selectedMediaIds.length > 1) {
                props.setSweetAlert({ description: 'You can not add more than one preview.' })
                return
            }
            setSelectedPreviewMedia(selectedMediaIds)
        } else {
            setShowMediaSelectionPopup(false)
            setSelectedMedia(selectedMediaIds)
        }
        const selectedMediaObjects = allMedia.filter(media => selectedMediaIds.includes(media._id))
        const sortedMedia = sortArrayById(selectedMediaObjects, selectedMediaIds)
        if (sortedMedia.length > 0) {
            const selectedFiles = sortedMedia.map(item => {
                const getType = {
                    photo: 'image/jpeg',
                    video: 'video/mp4'
                }
                const type = getType[item.type]
                return {
                    path: item.path,
                    small_thumb: item.small_thumb,
                    format: 'modern',
                    type: type,
                    size: item.size,
                    id: item._id
                }
            })
            galleryFiles(selectedFiles, name)
        }
    }

    const hourArray = new Array(12).fill(0)
    const minArray = new Array(60).fill(0)

    const handleSelectedMedia = (media) => {
        let name = 'original'
        if (showMediaPreviewSelectionPopup === true) {
            name = 'preview'
        }
        handleMedia(media, name)
    }

    return (<>
        <WebsiteFontColor>
            {isDialogOpen === true &&
                <FullScreenModelPopUpDialog
                    url={url}
                    handleClose={() => { openDialog('', '') }}
                    type={type}
                />
            }
            {isDialogOpen === false &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ backgroundColor: card_background_color }}>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Mass Message</h5>
                                <button className='close' onClick={props.handleClose} style={{ color: site_font_color, opacity: 1 }} disabled={disableButtons} />
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <div className='form-row'>
                                        <div className='form-group col'>
                                            <textarea
                                                id='description'
                                                name='description'
                                                className='form-control'
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                disabled={disableButtons}
                                                placeholder='Write your message...'
                                            />
                                        </div>
                                    </div>
                                    <PreviewContent
                                        contentManagerMedia={contentManagerMedia}
                                        media={media}
                                        mediaPreview={mediaPreview}
                                        contentMediaPreview={contentMediaPreview}
                                        isLoading={disableButtons}
                                        handleDeletePhoto={handleDeletePhoto}
                                        handleDrag={handleDrag}
                                        handleDrop={handleDrop}
                                        reArrangeImage={reArrangeImage}
                                        uploadProgress={uploadProgress}
                                        uploadProgressInMb={uploadProgressInMb}
                                        handleGalleryChange={handleGalleryChange}
                                        option={option}
                                        onChangePreviewMode={onChangePreviewMode}
                                        isPreviewModeEnable={isPreviewModeEnable}
                                        galleryFilesFromPreview={galleryFilesFromPreview}
                                        openDialog={openDialog}
                                        galleryFilesFromOriginal={galleryFilesFromOriginal}
                                        isRearrangeModeEnable={isRearrangeModeEnable}
                                        setIsRearrangeModeEnable={setIsRearrangeModeEnable}
                                        showMarkAsPreviewButton={false}
                                        isSelectedFromContentManager={isSelectedContentManager}
                                        setShowMediaSelectionPopup={setShowMediaSelectionPopup}
                                        setShowMediaPreviewSelectionPopup={setShowMediaPreviewSelectionPopup}
                                        setIsRearrangeModeEnableForMedia={setIsRearrangeModeEnableForMedia}
                                        isRearrangeModeEnableForMedia={isRearrangeModeEnableForMedia}
                                        isPreviewModeEnableForMedia={isPreviewModeEnableForMedia}
                                        setIsSelectedContentManager={setIsSelectedContentManager}
                                        requestFrom='mass-message'
                                        setSelectedCategory={setSelectedCategory}
                                        selectedCategory={selectedCategory}
                                    />
                                    <ButtonGroup className='button-group d-flex mt-5' content_color={content_color} content_font_color={content_font_color} button_shadow_color={button_shadow_color}>
                                        <button type='button'
                                            className={classNames('btn col mb-2 p-2 m-0 rounded-start', { 'border-2 selectedOption': option === 'locked', 'shadow-none': option !== 'locked' })}
                                            onClick={() => setOption('locked')}
                                            style={{
                                                borderRadius: '0% 0% 0% 0%',
                                                backgroundColor: option === 'locked' && content_color,
                                                color: option === 'locked' ? content_font_color : site_font_color,
                                                borderColor: option !== 'locked' && content_color
                                            }}
                                            disabled={disableButtons}
                                        >
                                            Locked
                                        </button>
                                        <button type='button'
                                            className={classNames('btn col mb-2 p-2 m-0 rounded-0', { 'border-2 selectedOption': option === 'free', 'shadow-none': option !== 'free' })}
                                            onClick={() => {
                                                setOption('free')
                                                setMediaPreview([])
                                                setContentMediaPreview([])
                                                setIsPreviewModeEnable(false)
                                                setIsPreviewModeEnableForMedia(false)
                                                setSelectedPreviewMedia([])
                                            }}
                                            style={{
                                                borderRadius: '0% 0% 0% 0%',
                                                backgroundColor: option === 'free' && content_color,
                                                color: option === 'free' ? content_font_color : site_font_color,
                                                borderColor: option !== 'free' && content_color
                                            }}
                                            disabled={disableButtons}
                                        >
                                            Free
                                        </button>
                                    </ButtonGroup>
                                    <div className='row'>
                                        <div className='col-12 py-3'>
                                            {sendTo !== 'ONLY_NEW' &&
                                                <>
                                                    {isSchedule ?
                                                        <>
                                                            <label style={{ display: 'flex', alignItems: 'center', margin: '0', paddingTop: '10px' }}>Date</label>
                                                            <div className=''>
                                                                <DatePickerDiv>
                                                                    <DatePicker
                                                                        name="schedulePostTime"
                                                                        selected={schedulePostTime}
                                                                        onChange={date => setSchedulePostTime(date)}
                                                                        minDate={new Date()}
                                                                        className={'form-control react-datepicker-input'}
                                                                        dateFormat='MMMM d, yyyy'
                                                                        disabled={disableButtons}
                                                                    />
                                                                </DatePickerDiv>
                                                            </div>
                                                            <div className='whole-time-box pt-2'>
                                                                <label style={{ display: 'flex', alignItems: 'center', margin: '0' }}>Time</label>
                                                                <div className='schedule-box' style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <select name='schedulePostHour'
                                                                        value={schedulePostHour}
                                                                        onChange={(e) => setSchedulePostHour(e.target.value)}
                                                                        className='form-control form-select'
                                                                        style={{ textAlignLast: 'center' }}
                                                                        disabled={disableButtons}
                                                                    >
                                                                        {hourArray.map((d, i) => {
                                                                            return <option key={'hour' + i} value={i + 1}>{i + 1}</option>
                                                                        })
                                                                        }
                                                                    </select>
                                                                    <select name='schedulePostMin'
                                                                        value={schedulePostMin}
                                                                        onChange={(e) => setSchedulePostMin(e.target.value)}
                                                                        className='form-control ml-1 form-select'
                                                                        style={{ textAlignLast: 'center' }}
                                                                        disabled={disableButtons}
                                                                    >
                                                                        {minArray.map((d, i) => {
                                                                            return <option key={'min' + i} value={i}>{i < 10 ? '0' + (i) : i}</option>
                                                                        })
                                                                        }
                                                                    </select>
                                                                    <select name='schedulePostTimeFormat'
                                                                        value={schedulePostTimeFormat}
                                                                        onChange={(e) => setSchedulePostTimeFormat(e.target.value)}
                                                                        className='form-control ml-1 form-select'
                                                                        style={{ textAlignLast: 'center' }}
                                                                        disabled={disableButtons}
                                                                    >
                                                                        <option value='AM'>AM</option>
                                                                        <option value='PM'>PM</option>
                                                                    </select>
                                                                </div>
                                                                <div className='mt-3'>
                                                                    <button className='btn m-0' onClick={() => setIsSchedule(!isSchedule)}
                                                                        style={{ backgroundColor: content_color, color: content_font_color }}
                                                                        disabled={disableButtons}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <Button
                                                            onClick={() => setIsSchedule(!isSchedule)}
                                                            style={{
                                                                textTransform: 'none',
                                                                backgroundColor: content_color,
                                                                color: content_font_color,
                                                                boxShadow: `0 5px 15px 0 ${button_shadow_color}44, 0 4px 15px 0 ${button_shadow_color}44`
                                                            }}
                                                            disabled={disableButtons}
                                                        >
                                                            <AlarmIcon style={{ fontSize: '30px' }} />
                                                            Schedule Mass for Existing Subscribers
                                                        </Button>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='form-group'>
                                            <div className='from-check'>
                                                <label>Send to:</label><HelperButton className='mx-1'
                                                    disabled={disableButtons}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setHelperModelOpen(true)
                                                    }} style={{ backgroundColor: 'transparent', color: content_color }}>
                                                    <i className='fas fa-question-circle'></i>
                                                </HelperButton>
                                            </div>
                                            <div className='from-check'>
                                                <input
                                                    className='form-check-input position-static me-3 ms-0'
                                                    type='radio'
                                                    value='EVERYONE'
                                                    checked={sendTo === 'EVERYONE'}
                                                    onChange={handleSendOptionChange}
                                                    name='everyone'
                                                    id='everyone'
                                                    disabled={disableButtons}
                                                />
                                                <label className='form-check-label' htmlFor='everyone'>both current and future subscribers</label>
                                            </div>
                                            <div className='from-check'>
                                                <input
                                                    className='form-check-input position-static me-3 ms-0'
                                                    type='radio'
                                                    value='ONLY_EXISTING'
                                                    checked={sendTo === 'ONLY_EXISTING'}
                                                    onChange={handleSendOptionChange}
                                                    name='only_existing'
                                                    id='only_existing'
                                                    disabled={disableButtons}
                                                />
                                                <label className='form-check-label' htmlFor='only_existing'>only current subscribers</label>
                                            </div>
                                            <div className='from-check'>
                                                <input
                                                    className='form-check-input position-static me-3 ms-0'
                                                    type='radio'
                                                    value='ONLY_NEW'
                                                    checked={sendTo === 'ONLY_NEW'}
                                                    onChange={handleSendOptionChange}
                                                    name='only_new'
                                                    id='only_new'
                                                    disabled={disableButtons}
                                                />
                                                <label className='form-check-label' htmlFor='only_new'>only future subscribers</label>
                                            </div>
                                        </div>
                                    </div>
                                    {sendTo !== 'ONLY_EXISTING' ?
                                        <div className='row mt-2'>
                                            <div className='form-group'>
                                                <div className='from-check'>
                                                    <label>For future subscribers, send:</label><HelperButton disabled={disableButtons} className='mx-1' onClick={(e) => {
                                                        e.preventDefault()
                                                        setHelperModelFutureOpen(true)
                                                    }} style={{ backgroundColor: 'transparent', color: content_color }}>
                                                        <i className='fas fa-question-circle'></i>
                                                    </HelperButton>
                                                </div>
                                                <div className='from-check'>
                                                    <input
                                                        className='form-check-input position-static me-3 ms-0'
                                                        type='radio'
                                                        value='STAGGER_OVER_TIME'
                                                        checked={newUserMessageDelay === 'STAGGER_OVER_TIME'}
                                                        onChange={(e) => setNewUserMessageDelay(e.target.value)}
                                                        name='stagger_over_time_once_they_subscribe'
                                                        id='stagger_over_time_once_they_subscribe'
                                                        disabled={disableButtons}
                                                    />
                                                    <label className='form-check-label' htmlFor='stagger_over_time_once_they_subscribe'>stagger over time once they subscribe</label><br />
                                                </div>
                                                <div className='from-check'>
                                                    <input
                                                        className='form-check-input position-static me-3 ms-0'
                                                        type='radio'
                                                        value='IMMEDIATELY'
                                                        checked={newUserMessageDelay === 'IMMEDIATELY'}
                                                        onChange={(e) => setNewUserMessageDelay(e.target.value)}
                                                        name='immediately_once_they_subscribe'
                                                        id='immediately_once_they_subscribe'
                                                    />
                                                    <label className='form-check-label' htmlFor='immediately_once_they_subscribe'>immediately once they subscribe</label><br />
                                                </div>
                                                <div className='from-check'>
                                                    <input
                                                        className='form-check-input position-static me-3 ms-0'
                                                        type='radio'
                                                        value='DELAY_BY_HOURS'
                                                        checked={newUserMessageDelay === 'DELAY_BY_HOURS'}
                                                        onChange={(e) => setNewUserMessageDelay(e.target.value)}
                                                        name='delay_once_they_subscribe_by'
                                                        id='delay_once_they_subscribe_by'
                                                        disabled={disableButtons}
                                                    />
                                                    <label className='form-check-label' htmlFor='delay_once_they_subscribe_by'>delay once they subscribe by
                                                        <input
                                                            className="mr-2"
                                                            type='text'
                                                            value={sendToHour}
                                                            onChange={(e) => {
                                                                let count = (e.target.value.match(/\./g) || []).length
                                                                if (count <= 1) {
                                                                    setSendToHour(e.target.value)
                                                                }
                                                            }}
                                                            style={{ width: '40px', marginLeft: '8px', textAlign: 'center', lineHeight: '1.2', borderRadius: '5px' }}
                                                            name='sendToHour'
                                                            id='sendToHour'
                                                            disabled={disableButtons}
                                                        />hours
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {option === 'locked' &&
                                        <div className='form-row mb-3'>
                                            <div className='form-group col col-md-6'>
                                                <label htmlFor='amount'>Amount</label>
                                                <div className='input-group'>
                                                    <div className='input-group-prepend'>
                                                        <span className='input-group-text form-control'>$</span>
                                                    </div>
                                                    <input
                                                        type='number'
                                                        className='form-control'
                                                        id='amount'
                                                        name='amount'
                                                        value={amount}
                                                        onChange={(e) => setAmount(e.target.value)}
                                                        required
                                                        min={5}
                                                        max={450}
                                                        disabled={disableButtons}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className='row'>
                                        <div className='col'>
                                            <button
                                                className='btn btn-block'
                                                style={{
                                                    backgroundColor: content_color,
                                                    color: content_font_color,
                                                    boxShadow: `0 5px 15px 0 ${button_shadow_color}44, 0 4px 15px 0 ${button_shadow_color}44`
                                                }}
                                                onClick={sendMassMessage}
                                                disabled={disableButtons}
                                            >
                                                {disableButtons === true && <span className="spinner-border spinner-border-sm mr-2"></span>} Sent To All
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {helperModelOpen === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                <h5 className='modal-title'>Send to: </h5>
                                <button className='close' onClick={(e) => {
                                    e.preventDefault()
                                    setHelperModelOpen(false)
                                }} style={{ color: site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                <p>The options for Send to are:</p>
                                <ul>
                                    <li><b>Both current and future subscribers:</b> current subscribers will receive the message immediately and future subscribers will also receive the message after they join. This is how the old Mass Message worked. All messages you sent previously will continue to have this option, unless you change it.</li>
                                    <li><b>Only current subscribers:</b> Only current subscribers will receive the message. This option can be used for messages that have a time element. For example, if you’re wishing your subscribers a happy Valentines Day you wouldn’t want this message to be sent in the future to new subscribers.</li>
                                    <li><b>Only future subscribers:</b> Only future subscribers will receive it.  While we have a new Welcome Message feature for new subscribers you might want to use this choice to send followup messages to new users a few days after they join.  For example, you could ask them how they like the site after the first few days.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {helperModelFutureOpen === true &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-centered' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                <h5 className='modal-title'>For future subscribers, send:</h5>
                                <button className='close' onClick={(e) => {
                                    e.preventDefault()
                                    setHelperModelFutureOpen(false)
                                }} style={{ color: site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                <p>If a message is sent to future subscribers, we now have 3 new options:</p>
                                <ul>
                                    <li><b>Stagger over time once they subscribe:</b> Previously we sent Mass Messages to new subscribers as soon as they subscribed, so all of the sent Mass Messages were in their chat right away. If you choose the Stagger option, we’ll send the messages over time. The default range is 24 to 48 hours per message.  For example, say you have 10 messages set to Stagger. We’ll send the first message between 1 to 2 days after they join, the second another 1 to 2 days after the first one is sent, etc., etc. The goal is to make it appear more organic and real.</li>
                                    <li><b>Immediately once they subscribe:</b> As the description implies, we send the message immediately and it will be in their chat the first time they view it.  This is how all older Mass Messages were sent, and how they will continue to be sent unless you edit them.</li>
                                    <li><b>Delay once they subscribe by __ hours:</b> Using this option you can delay the message by X hours after the user subscribes.  The default is 2 hours but you can change it.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(showMediaSelectionPopup || showMediaPreviewSelectionPopup) &&
                <MediaSelectionPopup
                    title={showMediaPreviewSelectionPopup ? 'Select Preview' : 'Select Media'}
                    prevSelectedMedia={showMediaPreviewSelectionPopup ? selectedPreviewMediaId : selectedMediaId}
                    onClose={() => { showMediaPreviewSelectionPopup ? setShowMediaPreviewSelectionPopup(false) : setShowMediaSelectionPopup(false) }}
                    onSelection={(media) => { handleSelectedMedia(media) }}
                />
            }
        </WebsiteFontColor >
    </>
    )
}

NewMassMsgDialog.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    setSweetAlert: PropTypes.func.isRequired,
    _pageNum: PropTypes.number.isRequired,
    _sortBy: PropTypes.string,
    _userName: PropTypes.string,
    _sortBySubscribers: PropTypes.string,
    getUserList: PropTypes.func.isRequired,
    sendMassMessage: PropTypes.func.isRequired,
    media: PropTypes.object,
    updateMediaCountInCategory: PropTypes.func
}

const mapStateToProps = state => ({
    auth: state.auth,
    blog: state.blog,
    chat: state.chat,
    media: state.media
})

export default connect(
    mapStateToProps, { setSweetAlert, sendMassMessage, getUserList, updateMediaCountInCategory }
)(withRouter(withStyles(useStyles)(NewMassMsgDialog)))
