import axios from 'axios'
import _ from 'lodash'
import {
    SET_ALL_USERS,
    IS_LOADING,
    SET_TRANSACTION_AMOUNT,
    SET_ALL_USERS_TRANSACTIONS,
    IS_BLUR_LOADING,
    SET_IS_USER_LIST_LOADING,
    STOP_IS_BLUR_LOADING,
    SET_ROTATE_IMAGE,
    UPDATE_UNREAD_COUNT,
    SET_ROTATE_CHAT_IMAGE
} from './types'
import { getAppBaseUrl } from './api'
import { getUserDetails } from './authActions'
import { setSweetAlert, removeConfirmSweetAlert } from './sweetAlertActions'

const BASE_URL = getAppBaseUrl()

// Get All users`
export const getUserList = (data) => dispatch => {
    dispatch(setIsUserListLoading())
    axios.post(BASE_URL + `/api/users/get_users_list?page_num=${data.pageNum}&view=${data.view}`, data)
        .then(res => {
            dispatch({ type: SET_ALL_USERS, payload: res.data.data })
        })
}

export const getUserDetailsFromEmail = (data, callback) => dispatch => {
    axios.post(BASE_URL + `/api/users/get_users_list_from_email?page_num=${data.pageNum}`, data)
        .then(res => {
            dispatch({ type: SET_ALL_USERS_TRANSACTIONS, payload: res.data })
            return callback()
        })
}

export const getUserDetailsFromEmailAsync = (data) => async (dispatch) => {
    try {
        const res = await axios.post(BASE_URL + `/api/users/get_users_list_from_email?page_num=${data.pageNum}`, data)
        dispatch({ type: SET_ALL_USERS_TRANSACTIONS, payload: res.data })
    } catch (err) {
        throw new Error(err)
    }
}

// Get Transaction Details
export const getTransactionDetails = () => dispatch => {
    axios.get(BASE_URL + '/api/admin/get_transactions_details')
        .then(res => {
            dispatch({ type: SET_TRANSACTION_AMOUNT, payload: res.data })
        })
}

// Delete User and their details
export const deleteUser = (userId, data, callback) => dispatch => {
    axios.get(BASE_URL + `/api/admin/delete_user?user_id=${userId}`)
        .then((res) => {
            let message = _.get(res.data, 'message', 'User Deleted')
            const payload = {
                description: message
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            dispatch(getUserDetailsFromEmail(data, () => {
                return callback()
            }))
        }).catch(error => {
            let message = _.get(error, 'response.data.message', 'There was a problem in delete user.')
            const payload = { description: message }
            dispatch(setSweetAlert(payload))
            return callback()
        }).catch(error => {
            let message = _.get(error, 'response.data.message', 'There was a problem in delete user.')
            const payload = {
                description: message
            }
            dispatch(setSweetAlert(payload))
        })
}

// Revoke override limit for user
export const revokeLimit = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/admin/update_is_photo_id_accepted_status', data)
        .then(() => {
            const payload = {
                description: 'Override limit access revoked.'
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            dispatch(getUserDetailsFromEmail(data, () => {
                return callback()
            }))
        })
}

// Hide user from chat list
export const hideUserFromChatList = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/admin/update_hide_from_chat_status', data)
        .then(() => {
            const payload = {
                description: 'User hidden from chat list.'
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            dispatch(getUserDetailsFromEmail(data, () => {
                return callback()
            }))
        })
}

// Show user in chat list
export const showUserInChatList = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/admin/update_hide_from_chat_status', data)
        .then(() => {
            const payload = {
                description: 'User shown in chat list.'
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            dispatch(getUserDetailsFromEmail(data, () => {
                return callback()
            }))
        })
}

// Refresh Blur Image
export const refreshBlurImage = (data) => dispatch => {
    dispatch(setBlurLoading())
    const apiEndpoint = data.format === 'modern' ? 'refresh_blur_modern_picture' : 'refresh_blur_picture'
    axios.post(`${BASE_URL}/api/upload/${apiEndpoint}`, data)
        .then(res => {
            if (res.data.updated === true) {
                alert('Blur image refreshed.')
                window.location.reload()
            }

            if (res.data.updated === false) {
                alert('There was a problem in generating blur image.')
            }
        }).catch(err => {
            console.log(err)
            dispatch(stopBlurLoading())
        })
}

// Rotate Image
export const rotateImage = (data) => async (dispatch) => {
    dispatch(setBlurLoading())
    const apiEndpoint = (data.format === 'modern' && data.type !== 'message') ? 'rotate_modern_image' : 'rotate_image'
    const url = `${BASE_URL}/api/upload/${apiEndpoint}`
    try {
        const res = await axios.post(url, data)
        alert(res.data.message)
        dispatch(stopBlurLoading())
        if (data.type === 'message') {
            dispatch({ type: SET_ROTATE_CHAT_IMAGE, payload: res.data.data.response })
        } else {
            dispatch({ type: SET_ROTATE_IMAGE, payload: res.data.data.response })
        }
        return res.data
    } catch (error) {
        let errorMessage = _.get(error.response, 'data.message', 'There was an error while rotating the image.')
        alert(errorMessage)
        dispatch(stopBlurLoading())
    }
}

// Save Email Preferences
export const saveEmailPreferences = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/users/save_email_preferences', data)
        .then(res => {
            if (res.data.updated === true) {
                dispatch(getUserDetails(() => {
                    callback()
                }))
                return alert('Email Preferences Updated')
            }
        })
        .catch(error => {
            const errorMessage = _.get(error.response, 'data.message', 'There was an error while updating email preferences.')
            const payload = {
                description: errorMessage
            }
            dispatch(setSweetAlert(payload))
        })
}

// Send Video processing request again
export const sendVideoProcessingRequest = (data, callback) => dispatch => {
    dispatch(setBlurLoading())
    axios.post(BASE_URL + '/api/admin/send_video_process_request', data)
        .then(() => {
            dispatch(stopBlurLoading())
            alert('Video processing request sent.')
            return callback()
        })
        .catch(err => {
            console.log(err)
            dispatch(stopBlurLoading())
            callback()
        })
}

// Mute mass message for user
export const muteMassMessageForUser = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/admin/mute_message_for_user', data)
        .then(() => {
            const payload = {
                description: 'Mass messages muted for user.'
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            const muteFromChat = _.get(data, 'muteFromChat', false)
            if (muteFromChat === false) {
                dispatch(getUserDetailsFromEmail(data, () => {
                    return callback()
                }))
            }
        })
}

export const muteMassMessageForUserAsync = (data) => async (dispatch) => {
    try {
        await axios.post(BASE_URL + '/api/admin/mute_message_for_user', data)
        const payload = {
            description: 'Mass messages muted for user.'
        }
        dispatch(setSweetAlert(payload))
        dispatch(removeConfirmSweetAlert())
        const muteFromChat = _.get(data, 'muteFromChat', false)
        if (muteFromChat === false) {
            dispatch(getUserDetailsFromEmail(data, () => { }))
        }
        return { success: 1 }
    } catch (err) {
        const message = _.get(err, 'response.data.message', 'Error occured while muting mass message for user')
        dispatch(removeConfirmSweetAlert())
        const payload = { description: message }
        dispatch(setSweetAlert(payload))
        return { success: 0 }
    }
}

// Unmute mass message for user
export const unmuteMassMessageForUser = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/admin/mute_message_for_user', data)
        .then(() => {
            const payload = {
                description: 'Mass messages unmuted for user.'
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            const muteFromChat = _.get(data, 'muteFromChat', false)
            if (muteFromChat === false) {
                dispatch(getUserDetailsFromEmail(data, () => {
                    return callback()
                }))
            }
        })
}

export const unmuteMassMessageForUserAsync = (data) => async (dispatch) => {
    try {
        await axios.post(BASE_URL + '/api/admin/mute_message_for_user', data)
        const payload = {
            description: 'Mass messages unmuted for user.'
        }
        dispatch(setSweetAlert(payload))
        dispatch(removeConfirmSweetAlert())
        const muteFromChat = _.get(data, 'muteFromChat', false)
        if (muteFromChat === false) {
            dispatch(getUserDetailsFromEmail(data))
        }
        return { success: 1 }
    } catch (err) {
        const message = _.get(err, 'response.data.message', 'Error occured while unmuting mass message for user')
        dispatch(removeConfirmSweetAlert())
        const payload = { description: message }
        dispatch(setSweetAlert(payload))
        return { success: 0 }
    }
}

// Grant override limit for user
export const grantOverrideLimit = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/admin/add_is_photo_id_accepted_status', data)
        .then(() => {
            const payload = {
                description: 'Override limit access granted.'
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            dispatch(getUserDetailsFromEmail(data, () => {
                return callback()
            }))
        })
}

// Enable/Disable user chat email notifications
export const updateUserChatEmailNotifications = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/admin/update_user_chat_email_notifications', data)
        .then(res => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            dispatch(getUserDetailsFromEmail(data, () => {
                return callback()
            }))
        })
}

// Block/Unblock User
export const updateUserBlockedStatus = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/admin/update_user_block_status', data)
        .then(res => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            dispatch(getUserDetailsFromEmail(data, () => {
                return callback()
            }))
        }).catch(error => {
            const message = _.get(error, 'response.data.message', 'There was a problem in Block user.')
            dispatch(removeConfirmSweetAlert())
            const payload = { description: message }
            dispatch(setSweetAlert(payload))
            return callback()
        })
}

export const updateUserBlockedStatusAsync = (data) => async (dispatch) => {
    try {
        const res = await axios.post(BASE_URL + '/api/admin/update_user_block_status', data)
        const payload = {
            description: res.data.message
        }
        dispatch(setSweetAlert(payload))
        dispatch(removeConfirmSweetAlert())
        await dispatch(getUserDetailsFromEmailAsync(data))
        return { success: 1 }
    } catch (err) {
        const message = _.get(err, 'response.data.message', 'There was a problem in Block user')
        dispatch(removeConfirmSweetAlert())
        const payload = { description: message }
        dispatch(setSweetAlert(payload))
        return { success: 0 }
    }
}

// Block/Unblock User in v2
export const blockUserAndCancelSubscription = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/v2/admin/block-user', data)
        .then(res => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            dispatch(getUserDetailsFromEmail(data, () => {
                return callback()
            }))
        }).catch(error => {
            const message = _.get(error, 'response.data.message', 'There was a problem in Block user.')
            const payload = { description: message }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            return callback()
        })
}

export const updateUserMigrationSubscription = (data, callback) => dispatch => {
    axios.post(BASE_URL + '/api/admin/update_user_migration_subscription', data)
        .then(res => {
            const payload = {
                description: res.data.message
            }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            dispatch(getUserDetailsFromEmail(data, () => {
                return callback()
            }))
        }).catch(error => {
            const message = _.get(error, 'response.data.message', 'There was a problem in update user detail.')
            const payload = { description: message }
            dispatch(setSweetAlert(payload))
            dispatch(removeConfirmSweetAlert())
            return callback()
        })
}

export const updateUsersNickName = (data, callback) => () => {
    axios.post(BASE_URL + '/api/users/add_nick_name', data)
        .then(res => {
            return callback(res.data.message)
        })
}

/**
 * Update users nickname
 * @param {object} data user id and nick name
 * @returns {object} response data object
 */
export const updateUsersNickNameAsync = (data) => async (dispatch) => {
    try {
        const res = await axios.post(BASE_URL + '/api/users/add_nick_name', data)
        const description = res.data.success === 1 ? 'Nickname updated successfully' : 'Error occured while updating nickname'
        dispatch(setSweetAlert({ description }))
        return res.data
    } catch (err) {
        const message = _.get(err, 'response.data.message', 'Error occured while updating nickname')
        dispatch(removeConfirmSweetAlert())
        const payload = { description: message }
        dispatch(setSweetAlert(payload))
    }
}

/**
 * Mark user conversation as unread
 * @param {object} data user id and model id
 * @returns {object} response data object
 */
export const markChatAsUnread = (data) => async (dispatch) => {
    const url = `${BASE_URL}/api/admin/mark-chat-as-unread`
    try {
        const response = await axios.post(url, data)
        const payload = {
            description: response.data.message
        }
        dispatch(setSweetAlert(payload))
        if (response.data.success === 1) {
            dispatch(updateUnreadMessageCount(response.data.data))
        }
        return response.data
    } catch (error) {
        const errorMessage = _.get(error.response, 'data.message', 'Invalid request.')
        const payload = {
            description: errorMessage
        }
        dispatch(setSweetAlert(payload))
        return error.response.data
    }
}

/**
 * Give complimentary subscription
 *
 * @param {object} data userId
 * @param {object} filter Get list of user  using filter
 */
export const giveComplimentarySubscription = (data, filter) => async (dispatch) => {
    const url = `${BASE_URL}/api/admin/give_complimentary_subscription`
    let alertMessage = ''
    try {
        const res = await axios.post(url, data)
        alertMessage = res.data.message
        dispatch(getUserDetailsFromEmail(filter, () => { }))
    } catch (error) {
        alertMessage = _.get(error, 'response.data.message', 'Error in give a complimentary subscription to the user')
    } finally {
        dispatch(removeConfirmSweetAlert())
        dispatch(setSweetAlert({ description: alertMessage }))
    }
}

// Set loading
export const setLoading = () => {
    return {
        type: IS_LOADING
    }
}

// Set blur button loading
export const setBlurLoading = () => {
    return {
        type: IS_BLUR_LOADING
    }
}

// Set blur button loading
export const stopBlurLoading = () => {
    return {
        type: STOP_IS_BLUR_LOADING
    }
}

// Set User list loading
export const setIsUserListLoading = () => {
    return {
        type: SET_IS_USER_LIST_LOADING
    }
}


export const updateUnreadMessageCount = (data) => {
    return {
        type: UPDATE_UNREAD_COUNT,
        payload: data
    }
}
