import React, { useCallback, useEffect, useRef, useState } from 'react'
import Janus, { publishToRoom, unPublishOwnFeed, publishOwnFeed } from 'janus-js-core'
import socket from '../../utils/socket'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import GroupLiveStreamModal from './GroupLiveStreamModal'
import { startStream, stopStream, removeGroupStreamData } from '../../actions/streamAction'
import { showBootstrapNavbar } from '../../actions/chatActions'
import { getAppBaseUrl } from '../../actions/api'
import styled from 'styled-components'
import LiveStreamModal from './LiveStreamModal'

const BASE_URL = getAppBaseUrl()

const HelperButton = styled.button`
border-radius: 25px;
border: none;
font-size: 20px;
vertical-align: middle;
`
const LiveStreamMainDiv = styled.div`
    // width: auto;
    // justify-content: flex-start;
    //     @media(max-width: 991px) {
    //         justify-content: unset;
    //         text-align: left;
    //         padding-left: 10px;
    //     }
    //     @media(max-width: 768px){
    //         text-align: center;
    //     }
    // .end-live-btn {
    //     position: absolute;
    //     right: 0;
    //     top: 3px;
    //     padding-right: 25px;
    // }

`

const LiveStreamButtonDiv = styled.div`
`

const Loader = styled.div`
`

const StreamPublisher = (props) => {

    const $video = useRef(null) // ref to video element for media stream

    const [playerStatus, setPlayerStatus] = useState('ready')
    const [modalIsOpen, setIsOpen] = React.useState(false)
    const [isPublisherMuted, setPublisherIsMuted] = useState(false)
    const [sfutest, setSfuTest] = useState(null)
    const [goLiveDisable, setGoLiveDisable] = useState(false)
    const [helperModelOpen, setHelperModelOpen] = useState(false)
    const sfuTestRef = useRef(sfutest)
    const { id } = props.match.params
    const [activeDeviceId, setActiveDeviceId] = useState('')
    const [changedDeviceId, setChangedDeviceId] = useState('')
    const [numberOfVideoDevices, setNumberOfVideoDevices] = useState(0)
    const [videoInputList, setVideoInputList] = useState([])
    const [audioInputList, setAudioInputList] = useState([])

    const getStreamRecordName = (id) => {
        return `${BASE_URL.replace('://', '_')}_LIVE_STREAM_REC_${id}`
    }

    useEffect(() => {
        if (props.isGroupLive) {
            socket.on('STOP_CAMERA_AUDIO', () => {
                unPublishOwnFeed(sfuTestRef.current)
            })
        }
    }, [])

    useEffect(() => {
        sfuTestRef.current = sfutest
    }, [sfutest])

    const getCameraDetails = () => {
        const config = { audio: true, video: true }
        navigator.mediaDevices
            .getUserMedia(config)
            .then(function () {
                navigator.mediaDevices.enumerateDevices().then(function (devices) {
                    const videoDevices = devices.filter(device => device.kind === 'videoinput')
                    const audioDevices = devices.filter(device => device.kind === 'audioinput')

                    if (videoDevices.length > 1) {
                        setActiveDeviceId(videoDevices[0].deviceId)
                        setChangedDeviceId(videoDevices[1].deviceId)
                        setNumberOfVideoDevices(videoDevices.length)
                    }

                    setVideoInputList(videoDevices)
                    setAudioInputList(audioDevices)
                })
            })
    }

    useEffect(() => {
        const { role } = props.auth.user
        if (props.isGroupLive === true && playerStatus === 'live' && (role === 'model' || role === 'live_stream_manager')) {
            socket.emit('GROUP_LIVE_STARTED', props.tabToken)
        }
    }, [playerStatus])

    const handleStartClick = useCallback(() => {
        if (sfutest) {
            const data = {
                userId: id
            }
            if (props.isGroupLive === true) {
                data.isGroupLiveStream = true
            }
            props.startStream(data, (res) => {
                console.log('model started Go Live stream')
                props.onGoLive()
                if (props.isGroupLive !== true) {
                    const userData = {
                        userId: id,
                        tabToken: props.tabToken
                    }
                    socket.emit('START_LIVE_STREAM', userData)
                }
                const filename = getStreamRecordName(res.data._id)
                publishOwnFeed({ sfutest, useAudio: true, record: true, filename: filename })
                setIsOpen(true)
                setGoLiveDisable(true)
                getCameraDetails()
            })
        }
    })

    useEffect(() => {
        if (sfutest !== null && props.isGroupLive === true) {
            handleStartClick()
        }
    }, [sfutest])

    const handleStopClick = () => {
        if (window.confirm('Are you sure that you want to end the Go Live session?')) {
            if (sfutest) {
                console.log('model ended Go Live stream')
                props.onStopLive()
                unPublishOwnFeed(sfutest)
                setIsOpen(false)
                if (props.isGroupLive === true) {
                    props.removeGroupStreamData()
                    socket.emit('STOP_GROUP_LIVE_STREAM')
                } else {
                    socket.emit('STOP_LIVE_STREAM', id)
                }
                setGoLiveDisable(false)
                props.stopStream()
                if (props.isGroupLive === true) {
                    goToPreviousPath()
                }
            }
        }
    }

    const handleMuteClick = () => {
        if (!sfutest.isAudioMuted()) {
            sfutest.muteAudio()
        }
        setPublisherIsMuted(true)
    }

    const handleUnMuteClick = () => {
        if (sfutest.isAudioMuted()) {
            sfutest.unmuteAudio()
        }
        setPublisherIsMuted(false)
    }

    // const handleBandwidthChange = (bitrate) => {
    //     sfutest.send({ message: { request: 'configure', bitrate } })
    // }

    const history = props.history
    const goToPreviousPath = () => {
        history.goBack()
    }

    const handlePublishToRoomCallback = useCallback(
        (_sfutest, eventType, data) => {
            setSfuTest(_sfutest)
            switch (eventType) {
                case 'created': {
                    props.setRoom(data.room)
                    break
                }
                case 'joined': {
                    const { id, private_id } = data
                    props.setPubId(id)
                    props.setPubPvtId(private_id)
                    setPlayerStatus('stop')

                    break
                }
                case 'onlocalstream': {
                    Janus.attachMediaStream($video.current, data)
                    if (
                        _sfutest.webrtcStuff.pc.iceConnectionState !== 'completed' &&
                        _sfutest.webrtcStuff.pc.iceConnectionState !== 'connected'
                    ) {
                        setPlayerStatus('live')
                    }

                    break
                }
                case 'oncleanup': {
                    setPlayerStatus('stop')
                    // use in production mode, will cause problem when publisher and viewer in one page like in demo
                    // state.janusInstance.destroy()
                    // setPublisherIsMuted(false)

                    break
                }
                case 'error': {
                    setPlayerStatus('error')
                    // setPublisherIsMuted(false)
                    break
                }
                default:
                    break
            }
        }, [props.setPubId, props.setRoom, props.setPubPvtId]
    )

    useEffect(() => {
        if (props.janusInstance) {
            // creating videoroom
            publishToRoom({
                janus: props.janusInstance,
                opaqueId: 'user.id',
                room: undefined,
                secret: 'user.id',
                pin: undefined,
                username: 'user.nickname',
                isPublisher: true,
                callback: handlePublishToRoomCallback,
                rec_dir: '/recordings'
            })
            // handleStartClick()
        }
    }, [handlePublishToRoomCallback, props.janusInstance])

    const { content_color, content_font_color } = props.auth.appSettings

    const onSwitchCamera = (videoDeviceId, audioDeviceId = '') => {
        let isAudioEnabled = !isPublisherMuted
        let body = { audio: isAudioEnabled, video: true }
        sfutest.createOffer(
            {
                // We provide a specific device ID for both audio and video
                media: {
                    audio: {
                        deviceId: audioDeviceId ? { exact: audioDeviceId } : undefined
                    },
                    video: {
                        deviceId: typeof videoDeviceId === 'string' ? (videoDeviceId ? { exact: videoDeviceId } : undefined) : (changedDeviceId ? { exact: changedDeviceId } : undefined)
                    },
                    replaceAudio: isAudioEnabled,
                    replaceVideo: true,	// This is only needed in case of a renegotiation
                    data: true	// Let's negotiate data channels as well
                },
                simulcast: false,
                simulcast2: false,
                // If you want to test simulcasting (Chrome and Firefox only), then
                // pass a ?simulcast=true when opening this demo page: it will turn
                // the following 'simulcast' property to pass to janus.js to true
                success: function (jsep) {
                    Janus.debug('Got SDP!', jsep)
                    sfutest.send({ message: body, jsep: jsep })
                    setActiveDeviceId(changedDeviceId)
                    setChangedDeviceId(activeDeviceId)
                },
                error: function (error) {
                    alert('WebRTC error')
                    Janus.error('WebRTC error:', error)
                }
            })
    }

    return (
        <>
            {props.isGroupLive !== true &&
                <>
                    {props.loader === false ? playerStatus === 'live' ?
                        <button onClick={handleStopClick} className='btn shadow-none' style={{ backgroundColor: content_color, color: content_font_color }}>END LIVE</button>
                        :
                        <LiveStreamButtonDiv>
                            <button onClick={handleStartClick} disabled={goLiveDisable} className='btn shadow-none'
                                style={{ backgroundColor: content_color, color: content_font_color }}>GO LIVE</button>
                            <HelperButton onClick={() => { setHelperModelOpen(true); props.showBootstrapNavbar(true) }} style={{ backgroundColor: 'transparent', color: content_color }}>
                                <i className='fas fa-question-circle'></i>
                            </HelperButton>
                        </LiveStreamButtonDiv>
                        :
                        <Loader className='spinner-border' style={{ color: content_color }} role='status'>
                        </Loader>
                    }
                </>
            }

            {/* <StreamControls
                        status={playerStatus}
                        onBandwidthChange={handleBandwidthChange}
                        onStop={handleStopClick}
                        onMute={handleMuteClick}
                        onUnmute={handleUnMuteClick}
                        isMuted={isMuted}
                        onStart={handleStartClick}
                    /> */}
            {helperModelOpen &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080', zIndex: '5' }}>
                    <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                        <div className='modal-content' style={{ color: props.auth.appSettings.site_font_color }}>
                            <div className='modal-header' style={{ backgroundColor: props.auth.appSettings.card_background_color }}>
                                <h5 className='modal-title'>Go Live Stream</h5>
                                <button className='close' onClick={() => { setHelperModelOpen(false); props.showBootstrapNavbar(false) }} style={{ color: props.auth.appSettings.site_font_color, opacity: 1 }} />
                            </div>
                            <div className='modal-body text-left' style={{ backgroundColor: props.auth.appSettings.card_background_color }}>
                                <b>STARTING GO LIVE & TIPPING</b>
                                <p>
                                    Pressing the GO LIVE button will immediately start a video session with the subscriber. The subscriber must be in chat to see you.
                                    We suggest planning this with the subscriber and in most cases you’ll want to agree on a price and length of the session.
                                    Most influencers will want to receive a tip for the agreed upon amount prior to the session but this is completely optional.
                                    The subscriber can also leave tips during and after the session.
                                </p>
                                <b>
                                    GO LIVE VIEW
                                </b>
                                <p>
                                    The Go Live feature looks and works very similar to Instagram Go Live. The subscriber can see you, but there is no video for the subscriber.
                                    They can chat with you during the session. The chat is shown over the video and takes up a small amount of the screen.
                                    For the subscriber, the chat will only be shown on the bottom 3rd of the screen, and in your view the chat will be shown on the bottom half of the screen.
                                    Subscribers can unlock previous locked content during the session so you are encouraged to ask the subscriber to unlock previous content.
                                </p>
                                <b>
                                    SOUND IN GO LIVE
                                </b>
                                <p>
                                    When GO LIVE begins, the sound is set to off for the subscriber for privacy reasons but the subscriber is prompted to press the sound icon at the top of their screen to toggle sound on.
                                    You have a microphone icon at the top of your screen if you want to mute your sound.
                                </p>
                                <b>
                                    SUBSCRIBER CAN LEAVE AND RETURN
                                </b>
                                <p>
                                    If the subscriber wants to temporarily leave chat to check other content sections (for example, to upload content outside of Go Live, or to view/purchase content in their normal feed) they can do so by hitting the X on the top-right of the chat screen.
                                    You will be notified that they left. The subscriber can return to Go Live by pressing a JOIN button at the top of their screen.
                                </p>
                                <b>
                                    ENDING GO LIVE
                                </b>
                                <p>
                                    You can end the session at any time by pressing the X on the top-right of your screen.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                modalIsOpen === true && props.isGroupLive === true &&
                <GroupLiveStreamModal
                    video={$video}
                    handleStopClick={handleStopClick}
                    isPublisher={true}
                    isPublisherMuted={isPublisherMuted}
                    onMute={handleMuteClick}
                    onUnmute={handleUnMuteClick}
                    onSwitchCamera={onSwitchCamera}
                    numberOfVideoDevices={numberOfVideoDevices}
                    audioInputList={audioInputList}
                    videoInputList={videoInputList}
                    isMobileDevice={window.screen.width < 576 ? true : false}
                />
            }
            {
                modalIsOpen === true && props.isGroupLive !== true &&
                <LiveStreamModal
                    video={$video}
                    handleStopClick={handleStopClick}
                    isPublisher={true}
                    isPublisherMuted={isPublisherMuted}
                    onMute={handleMuteClick}
                    onUnmute={handleUnMuteClick}
                    onSwitchCamera={onSwitchCamera}
                    numberOfVideoDevices={numberOfVideoDevices}
                    audioInputList={audioInputList}
                    videoInputList={videoInputList}
                    isMobileDevice={window.screen.width < 576 ? true : false}
                />
            }
        </>
    )
}

StreamPublisher.propTypes = {
    setPubId: PropTypes.func.isRequired,
    setRoom: PropTypes.func.isRequired,
    setPubPvtId: PropTypes.func.isRequired,
    janusInstance: PropTypes.object,
    onGoLive: PropTypes.func.isRequired,
    onStopLive: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    startStream: PropTypes.func.isRequired,
    tabToken: PropTypes.string.isRequired,
    loader: PropTypes.bool.isRequired,
    stopStream: PropTypes.func.isRequired,
    isGroupLive: PropTypes.bool,
    removeGroupStreamData: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    showBootstrapNavbar: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { startStream, stopStream, removeGroupStreamData, showBootstrapNavbar })(withRouter((StreamPublisher)))
