import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import formatUserLastSeen from '../../utils/formatUserLastSeen'
import socket from '../../utils/socket'
import LiveStream from './LiveStream'
import { setIsChatScreenOpen, setSelectedUserId, updateUserLastSeen, updateUserAmountSpent } from '../../actions/chatActions'


const ChatNewHeader = styled.div`
    position: sticky;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor};
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .flex-1{
        flex: 1;
        overflow: hidden;
    }

    .heading-sm {
        font-size: 18px;
    }

    @media(max-width: 576px){
        .heading-md {
            font-size: 18px;
        }
        .heading-sm {
            font-size: 16px;
        }
    }
`

const Image = styled.img`
    height: 40px !important;
    border-radius: 20px;
`

const UserLastSeen = styled.div`
    font-weight: 400;
    font-size: 14px;
`

const UserNickName = styled.span`
    color: #4169e1;
`

const Earning = styled.a`
    color: #32CD32;
    text-decoration: underline;
    white-space: nowrap;

    :hover {
        color: #32CD32;
    }
`

const RefundSpan = styled.span`
    white-space: no-wrap;
`

const ChatHeader = (props) => {
    const [lastSeen, setLastSeen] = useState('Offline')
    const [showPopup, setShowPopup] = useState(false)
    const [countDownRemainingTime, setCountDownRemainingTime] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const {
        chat,
        auth,
        handleTextMessageSend,
        scrollToBottom,
        canChat,
        setCanChat,
        chatStartTime,
        setChatStartTime,
        chatEndTime,
        setChatEndTime,
        chatEndTimeWithDelay,
        setChatEndTimeWithDelay,
        countDownDuration,
        setCountDownDuration,
        setIsChatHidden,
        pageNum,
        colorScheme
    } = props
    const { chatHeaderBackgroundColor, chatHeaderFontColor, chatBorderColor } = colorScheme
    const { role, isAdmin } = auth.user
    const { hide_earnings, card_background_color, card_header_border_color, content_font_color } = auth.appSettings

    let hideEarnings = isAdmin
        ? (role === 'content_manager' && hide_earnings) || role === 'live_stream_manager'
        : true

    let receiverId = props.auth.user.isAdmin
        ? props.match.params.id || props.chat.selectedUserId
        : props.chat.selectedUserId

    const receiverProfileDetails = (chat.userProfileInfo && chat.userProfileInfo[receiverId])
        ? chat.userProfileInfo[receiverId]
        : getCloudFrontAssetsUrl('faces/avatar.png')
    const profile = receiverProfileDetails.profile || getCloudFrontAssetsUrl('faces/avatar.png')

    const userWalletAmount = (userData) => {
        const walletAmount = _.get(userData, 'wallet_amount', false)
        return (['admin', 'sub_admin'].includes(role) && walletAmount !== false && walletAmount !== null)
            ? <span className='me-1'>&nbsp;<i className='fas fa-wallet me-1'></i>${walletAmount.toFixed(2)}</span>
            : null
    }

    const loadSocketEvent = () => {
        if (auth.user.isAdmin === true) {
            socket.on('ONLINE_USER_LIST', (users) => {
                if (users.includes(props.match.params.id)) {
                    setLastSeen('Online')
                } else {
                    setLastSeen('Offline')
                }
            })
            socket.on('USER_OFFLINE', async (userId) => {
                if (userId === props.match.params.id) {
                    props.updateUserLastSeen(userId)
                    setLastSeen(new Date())
                }
            })
            socket.on('UPDATE_USER_AMOUNT', async (userData) => {
                props.updateUserAmountSpent(userData)
            })
        }
    }

    const userLastSeen = (time) => {
        if (lastSeen === 'Online') return 'Online'
        if (lastSeen === 'Offline') return formatUserLastSeen(time)
        return formatUserLastSeen(lastSeen)
    }

    const refundAmount = () => {
        const voidAmount = receiverProfileDetails.totalVoidAmount
        const refundAmount = receiverProfileDetails.totalRefundAmount
        const chargeBackAmount = receiverProfileDetails.totalChargeBackAmount
        const chargeBackCount = receiverProfileDetails.totalChargeBackCount
        const totalRefundedAmount = voidAmount + refundAmount + chargeBackAmount + (25 * chargeBackCount)
        return (totalRefundedAmount > 0) ?
            <RefundSpan className='me-1 text-danger'>(${totalRefundedAmount.toFixed(2)})</RefundSpan>
            : <></>
    }

    useEffect(() => {
        if (props.auth.user.isAdmin) {
            socket.emit('GET_ONLINE_USER_LIST')
            const data = { adminId: props.auth.user._id, userId: props.match.params.id }
            socket.emit('ADMIN_IN_USER_CHAT', data)
        }
        loadSocketEvent()
    }, [])

    const onEndTimeCompleteTimer = () => {
        setChatStartTime(null)
        setChatEndTime(null)
        setShowPopup({ showPopup: true })
    }

    const onCompleteTimer = () => {
        let userId = auth.user._id

        if (auth.isAdmin) {
            userId = props.match.params.id
        }
        setCanChat(true)
        let data = {
            userId,
            timezone: momentTZ.tz.guess()
        }
        props.getBookedSlotData(data, (res) => {
            if (res.slotData.length > 0) {
                let startTime = moment().valueOf()
                let endTime = moment(res.slotData[0].meeting_end_time).valueOf()
                let chatEndTime = endTime - startTime + Date.now()
                setChatEndTime(chatEndTime)
                const chatEndDelay = (this.props.auth.appSettings.chat_settings.chat_end_delay * 60000) + chatEndTime
                setChatEndTimeWithDelay(chatEndDelay)
            }
        })
    }

    const handleExitChat = () => {
        props.history.push('/chat-new')
        props.setIsChatScreenOpen(false)
        props.setSelectedUserId('')
    }

    return (
        <ChatNewHeader
            className='p-2'
            backgroundColor={chatHeaderBackgroundColor}
            borderColor={chatBorderColor}
            color={chatHeaderFontColor}
        >
            <h3 className='d-flex flex-1 align-items-center mb-0'>
                <i className='fas fa-arrow-left d-inline d-lg-none mr-2' onClick={handleExitChat}></i>
                <Image alt='' draggable={false} className='img-fluid mr-2' src={profile} />
                <div className='d-flex flex-column'>
                    <p className='m-0 p-0 heading-md'> {receiverProfileDetails.name}</p>
                    <span className='heading-sm'>
                        {/* Wallet amount display */}
                        {userWalletAmount(receiverProfileDetails)}
                        {/* Wallet amount display */}
                        {!hideEarnings &&
                            <Earning
                                className='me-1'
                                target='_blank'
                                rel='noopener noreferrer'
                                href={`/purchased-contents/${receiverId}`}
                            >
                                (${receiverProfileDetails.totalAmountSpent ? receiverProfileDetails.totalAmountSpent.toFixed(2) : '0.00'})
                            </Earning>
                        }
                        {['admin', 'sub_admin'].includes(role) &&
                            refundAmount(receiverProfileDetails)
                        }
                        <UserNickName className='mt-1'>{receiverProfileDetails.nick_name}</UserNickName>
                    </span>
                    {isAdmin &&
                        <UserLastSeen className='d-flex align-items-center'>
                            {lastSeen === 'Online' ?
                                <svg height="20" width="20">
                                    <circle cx="10" cy="10" r="5" fill="#32CD32" />
                                </svg>
                                :
                                <></>
                            }
                            {userLastSeen(receiverProfileDetails.lastSeen)}
                        </UserLastSeen>
                    }
                </div>
            </h3>
            {/* Live stream button component */}
            <LiveStream
                receiverProfileDetails={receiverProfileDetails}
                receiverId={receiverId}
                handleTextMessageSend={handleTextMessageSend}
                scrollToBottom={scrollToBottom}
                canChat={canChat}
                setCanChat={setCanChat}
                chatStartTime={chatStartTime}
                setChatStartTime={setChatStartTime}
                chatEndTime={chatEndTime}
                chatEndTimeWithDelay={chatEndTimeWithDelay}
                countDownDuration={countDownDuration}
                setCountDownDuration={setCountDownDuration}
                onEndTimeCompleteTimer={onEndTimeCompleteTimer}
                onCompleteTimer={onCompleteTimer}
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                countDownRemainingTime={countDownRemainingTime}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                loadSocketEvent={loadSocketEvent}
            />
        </ChatNewHeader>
    )
}

ChatHeader.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setIsChatScreenOpen: PropTypes.func.isRequired,
    setSelectedUserId: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired,
    updateUserLastSeen: PropTypes.func.isRequired,
    updateUserAmountSpent: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    socket: state.socket
})

export default connect(
    mapStateToProps,
    {
        setIsChatScreenOpen,
        setSelectedUserId,
        updateUserLastSeen,
        updateUserAmountSpent
    }
)(withRouter(ChatHeader))
