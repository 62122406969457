import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NewMassMsgDialog from '../chat-new/NewMassMsgDialog'
import WelcomeMessage from '../chat-new/WelcomeMessage'
import Button from '../Layouts/Button'
import { getUserList, setSelectedChatModelId } from '../../actions/chatActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

const MainDiv = styled.div`
    background: ${props => props.backgroundColor};
    z-index: 2;
    border-bottom: 1px solid ${props => props.borderColor};
    
    .fas {
        color: ${props => props.fontColor};
    }

    .search-icon {
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    input, select {
        width:100%;
        height: 34px;
        font-size: 14px;
        padding: 6px 8px;
    }

    input {
        border: 1px solid ${props => props.borderColor};
        border-radius: 10px;
        :focus {
            outline: none;
        }
    }

    select {
        border: none;
        border-right: 10px solid transparent;
        outline: 1px solid ${props => props.borderColor};
        background: white;
    }
            
    select:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    select:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .fa-sort-amount-down {
        font-size: 18px;
    }

    #sort-by {
        flex: 20;
    }
    
    #sort-by-subscribers {
        flex: 12;
    }

    @media(max-width: 576px) {
        select.small-select {
            font-size: 12.5px;
            padding-left: 3px;
            padding-right: 0px;
            border-right: 2px solid transparent;
        }
    }
`

// Helper Function
function getSortByOptions(
    role,
    hideEarnings,
    isEnableSortBySubscriptionExpire,
    chatBookingEnabled,
    chatSettings,
    chatBookingTimer
) {
    let options = []
    if (role === 'content_manager' || role === 'live_stream_manager') {
        hideEarnings = true
    }

    if (role === 'admin' || role === 'sub_admin') {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Amount Spent', value: 'total_amount_spent' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' },
            { label: 'Days until Subscription Expires', value: 'expiration_date' }
        ]
    } else if (role === 'content_manager' && hideEarnings) {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' }
        ]
    } else if (role === 'live_stream_manager') {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' }
        ]
    } else if (role === 'model' && isEnableSortBySubscriptionExpire) {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Amount Spent', value: 'total_amount_spent' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' },
            { label: 'Days until Subscription Expires', value: 'expiration_date' }
        ]
    } else {
        options = [
            { label: 'Latest Message', value: 'last_message_time' },
            { label: 'Users Last Message', value: 'user_last_message_time' },
            { label: 'Amount Spent', value: 'total_amount_spent' },
            { label: 'Unread Messages', value: 'unreadcount.model_unread_message' }
        ]
    }

    if (chatBookingEnabled === true && (chatSettings && chatSettings.disabled_normal_chat === true)) {
        options.push({
            label: 'Filter By Allow User to Chat',
            value: 'FilterByChatAllowedUser'
        })
    }
    if (chatBookingTimer && chatBookingTimer.numberOfLiveChatUser > 0) {
        options.push({
            label: 'Filter By Live chats',
            value: 'FilterByLiveChats'
        })
    }

    const sortByOptions = options.map(option => (
        <option key={option.label} value={option.value}>
            {option.label}
        </option>
    ))
    return sortByOptions
}

// Helper Function
function getSortBySubscribersOptions() {
    const options = [
        { label: 'All', value: 'all' },
        { label: 'Active', value: '2' },
        { label: 'Active-Cancelled', value: '1' }
    ]
    const sortBySubsribersOptions = options.map(option => (
        <option key={option.label} value={option.value}>
            {option.label}
        </option>
    ))
    return sortBySubsribersOptions
}

// @ React Component
const ChatFilter = (props) => {

    const { auth, disabled, colorScheme } = props
    const {
        chatFilterBackgroundColor,
        chatFilterButtonBackgroundColor,
        chatFilterButtonFontColor
    } = colorScheme
    const { appSettings, user } = auth
    const {
        // content_color,
        // content_font_color,
        // chat_navbar_name,
        card_header_border_color,
        card_background_color,
        site_font_color,
        chat_booking_enabled,
        chat_settings,
        hide_earnings,
        // is_enable_user_block_access,
        // is_enable_mute_unmute_mass_message,
        // site_font_color,
        is_enable_sort_by_subscription_expire
        // is_enable_mark_as_unread,
        // model_id
    } = appSettings
    const role = user.role
    const enableSortBySubscribers = role === 'admin' ||
        role === 'sub_admin' ||
        (role === 'model' && is_enable_sort_by_subscription_expire)
    const selectClass = enableSortBySubscribers ? 'small-select' : ''

    const [isOpenMassMessageDialogu, setIsOpenMassMessageDialog] = useState(false)
    const [isOpenWelcomeMessageDialog, setIsOpenWelcomeMessageDialog] = useState(false)
    const [isHelperModalOpen, setIsHelperModalOpen] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const [sortBy, setSortBy] = useState(props.chat.sortBy ? props.chat.sortBy : 'last_message_time')
    const [sortBySubscribers, setSortBySubscribers] = useState(props.chat.sortBySubscribers ? props.chat.sortBySubscribers : 'all')
    const chatBookingTimer = props.chatBookingTimer

    const sortByOptions = getSortByOptions(
        role,
        hide_earnings,
        is_enable_sort_by_subscription_expire,
        chat_booking_enabled,
        chat_settings,
        chatBookingTimer
    )
    const sortBySubscribersOptions = getSortBySubscribersOptions()

    const handleChange = (e) => {
        let fieldName = e.target.name
        let value = e.target.value
        let _sortBySubscribers = props.chat.sortBySubscribers
        let _sortBy = props.chat.sortBy

        if (fieldName === 'sort_by' && value === 'expiration_date' && _sortBySubscribers !== '1') {
            _sortBySubscribers = '1'
            setSortBySubscribers(_sortBySubscribers)
        } else if (fieldName === 'sort_by_subscribers' && _sortBy === 'expiration_date') {
            value = '1'
        }

        if (fieldName === 'sort_by_subscribers') {
            _sortBySubscribers = value
            setSortBySubscribers(_sortBySubscribers)
        } else if (fieldName === 'sort_by') {
            _sortBy = value
            setSortBy(_sortBy)
        }

        props.getUserList({
            pageNum: 1,
            userName: searchInput,
            sortBy: _sortBy,
            isFilter: true,
            sortBySubscribers: _sortBySubscribers
        })
        props.setPage(1)
    }

    useEffect(() => {
        const modelId = appSettings.model_id
        props.setSelectedChatModelId(modelId)
    }, [])

    const handleSearch = () => {
        props.getUserList({
            pageNum: 1,
            userName: searchInput,
            sortBy: sortBy,
            isFilter: true,
            sortBySubscribers: props.chat.sortBySubscribers
        })
        props.setPage(1)
    }

    return (
        <>
            <MainDiv
                className='p-2 position-sticky top-0 left-0'
                backgroundColor={chatFilterBackgroundColor}
                fontColor={chatFilterButtonBackgroundColor}
                borderColor={chatFilterButtonBackgroundColor}>
                <div className='position-relative mb-2'>
                    <input
                        type='text'
                        id='search'
                        className=''
                        name='search'
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        disabled={disabled}
                        placeholder='Search...'
                        autoComplete='off'
                    />
                    <div className='search-icon position-absolute'>
                        <i className='fas fa-search'
                            onClick={handleSearch}></i>
                    </div>
                </div>
                <div className='d-flex mb-2 align-items-center' >
                    <i className='fas fa-sort-amount-down mr-2'></i>
                    <select
                        className={selectClass}
                        id='sort-by'
                        name='sort_by'
                        value={sortBy}
                        onChange={handleChange}
                        disabled={disabled}>
                        {sortByOptions}
                    </select>
                    {enableSortBySubscribers &&
                        <select
                            className={selectClass}
                            id='sort-by-subscribers'
                            name='sort_by_subscribers'
                            value={sortBySubscribers}
                            onChange={handleChange}
                            disabled={disabled}>
                            {sortBySubscribersOptions}
                        </select>
                    }
                </div>
                <div className='d-flex justify-content-between'>
                    <Button
                        backgroundColor={chatFilterButtonBackgroundColor}
                        fontColor={chatFilterButtonFontColor}
                        classes='m-0 col'
                        onClick={() => setIsOpenMassMessageDialog(true)}
                        loading={disabled}>
                        Send Mass Message
                    </Button>
                    <Button
                        backgroundColor={chatFilterButtonBackgroundColor}
                        fontColor={chatFilterButtonFontColor}
                        classes='m-0 col ml-2'
                        onClick={() => setIsOpenWelcomeMessageDialog(true)}
                        loading={disabled}>
                        Welcome Message
                    </Button>
                </div>
            </MainDiv>

            {/* TODO: update user list after sending mass message ? */}
            {/* Dialog to send Mass Message */}
            {
                isOpenMassMessageDialogu &&
                <NewMassMsgDialog
                    handleClose={() => setIsOpenMassMessageDialog(false)}
                />
            }

            {/* Dialog for Welcome Message */}
            {
                isOpenWelcomeMessageDialog &&
                <WelcomeMessage
                    // onSubmit={this.onSubmit}
                    // onChange={this.onChange}
                    handleWelcomeMassDlgClose={() => setIsOpenWelcomeMessageDialog(false)}
                    setHelperModelOpen={() => setIsHelperModalOpen(true)}
                />
            }

            {/* Helper modal */}
            {
                isHelperModalOpen &&
                <div className='modal fade show' role='dialog' style={{ display: 'block', backgroundColor: '#00000080' }}>
                    <div className='modal-dialog modal-dialog-scrollable modal-dialog-centered' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header' style={{ backgroundColor: card_background_color }}>
                                <h5 className='modal-title'>Welcome Message </h5>
                                <button
                                    className='close'
                                    onClick={() => setIsHelperModalOpen(false)}
                                    style={{ color: site_font_color, opacity: 1 }}
                                />
                            </div>
                            <div className='modal-body' style={{ backgroundColor: card_background_color }}>
                                <p>Send an automated welcome message to all new subscribers. This message can optionally include content and can be free or paid. You’ll find two options which determines when we send the message:</p>
                                <ul>
                                    <li><b>Delay once they subscribe:</b> this option allows you to set a time interval, in hours, which will delay the message so that it appears more organic. For example, you might choose to delay the message by 2.5 hours after the user subscribes. If you choose this option, the user receives the message after the delay interval.</li>
                                    <li><b>Immediately once they subscribe:</b> choose this option if you want the subscriber to see the message as soon as they subscribe.</li>
                                </ul>
                                <p>If you choose the WELCOME MESSAGE button after you already created a welcome message, you’ll have the option to edit the message. You can remove the content, change the caption, adjust the price, and modify the send options.</p>
                                <p>There is now a new ‘Welcome Messages’ link in Reporting. This will show the current Welcome Message at the top of the list and all past versions of the Welcome Message. You can PAUSE the current message thereby stopping future subscribers from seeing the message. You can also MAKE LIVE any past Welcome Messages.</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

ChatFilter.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    chatBookingTimer: PropTypes.object.isRequired,
    setSelectedChatModelId: PropTypes.func.isRequired,
    getUserList: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    setPage: PropTypes.func.isRequired,
    colorScheme: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    chatBookingTimer: state.chatBookingTimer
})

export default connect(
    mapStateToProps,
    {
        setSelectedChatModelId,
        getUserList
    }
)(withRouter(ChatFilter))
